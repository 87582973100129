import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from "reactstrap"
import { useReactToPrint } from "react-to-print"
import { getProductStockOutByIdRequest } from "../../store/product-stock-out/saga"
import ProductStockOutDetailToPrint from "./ProductStockOutDetailToPrint"

const PrintPreviewModal = props => {
  const { isOpen, toggle, productStockOutId, isPrintForWarehouse } = props
  const [ item, setItem ] = useState(null)

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      console.log("onBeforeGetContent print")

    },
    onAfterPrint: () => {
      console.log("After print")
      componentRef.current = null
      // setIsPrint(false);
    }
  })


  const onOpened = () => {
    getProductStockOutByIdRequest(productStockOutId).then(data => {
      setItem(data);
    });
  }

  return (
      <Modal
          size="xl"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Print Preview
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div style={ { height: "350px", overflow: "auto", background: "gray", padding: "10px" } }>
            {
              item ?
                  <ProductStockOutDetailToPrint
                      ref={ componentRef }
                      isPrintForWarehouse={isPrintForWarehouse}
                      item={item}
                  />:
                  <div className={"loading"}></div>
            }

          </div>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handlePrint }>
            <i className="mdi mdi-printer"/> Print Now
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

PrintPreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  productStockOutId: PropTypes.string.isRequired,
  isPrintForWarehouse: PropTypes.bool.isRequired,
}

export default PrintPreviewModal