import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_STOCK_LOCATION,
  GET_STOCK_LOCATION_BY_ID,
  UPDATE_STOCK_LOCATION,
  DELETE_STOCK_LOCATION,
  GET_STOCK_LOCATIONS,
} from "./actionTypes"
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getStockLocationByIdSuccess,
  getStockLocationsError,
  getStockLocationsSuccess,
} from "./actions"

const getStockLocationsRequest = query =>
  post("/api/StockLocation/paging", query);
const addNewStockLocationRequest = item =>
  post("/api/StockLocation", item);

const getStockLocationByIdRequest = id =>
  get(`/api/StockLocation/${id}`);
const updateStockLocationRequest = item =>
  update("/api/StockLocation", item);
const deleteStockLocationRequest = id =>
  del(`/api/StockLocation/${id}`);

function* getStockLocations({ payload }) {
  try {
    const response = yield call(getStockLocationsRequest, payload);
    yield put(getStockLocationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockLocationsError(error));
  }
}

function* addNewStockLocation({ payload }) {
  try {
    const { productId } = payload;
    yield call(addNewStockLocationRequest, payload);
    const response = yield call(getStockLocationsRequest, {
      productId,
    });
    yield put(getStockLocationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockLocationsError(error));
  }
}

function* updateStockLocation({ payload }) {
  try {
    const { productId } = payload;
    yield call(updateStockLocationRequest, payload);
    const response = yield call(getStockLocationsRequest, {
      productId,
    });
    yield put(getStockLocationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockLocationsError(error));
  }
}

function* getStockLocationById({ payload }) {
  try {
    let response = yield call(getStockLocationByIdRequest, payload);
    yield put(getStockLocationByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockLocationsError(error));
  }
}

function* deleteStockLocation({ payload }) {
  try {
    const { productId, id } = payload;
    yield call(deleteStockLocationRequest, id);
    const response = yield call(getStockLocationsRequest, {
      productId,
    });
    yield put(getStockLocationsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockLocationsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_LOCATIONS, getStockLocations);
  yield takeEvery(ADD_NEW_STOCK_LOCATION, addNewStockLocation);
  yield takeEvery(GET_STOCK_LOCATION_BY_ID, getStockLocationById);
  yield takeEvery(UPDATE_STOCK_LOCATION, updateStockLocation);
  yield takeEvery(DELETE_STOCK_LOCATION, deleteStockLocation);
}

export default itemSaga;
