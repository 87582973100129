import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import { NIL } from "uuid"
import PropTypes from "prop-types"
import CreatableSelect from "react-select/creatable"
import { manufacturerService } from "services/manufacturer-service"
import { isEmpty } from "lodash"
import { locationService } from "services/location-service"
import Select from "react-select"
import { getAllClothingSizesRequest } from "../../store/clothing-size/saga"
import { hasAccess } from "../../helpers/utils"
import { permission } from "../../constants/permission"
import { codeColorService } from "../../services/code-color-service"
import { categoryService } from "../../services/category-service"
import { getAllPurchaseOrdersRequest } from "../../store/purchase-order/saga"
import WarehouseSelect from "../warehouse/WarehouseSelect";

const ProductForm = ({ product, onSubmit, onCancel }) => {
  const [ id, setId ] = useState(NIL)
  const [ code, setCode ] = useState("")
  const [ name, setName ] = useState("")
  const [ sku, setSku ] = useState("")
  const [ purchaseOrderId, setPurchaseOrderId ] = useState(null)
  const [ itemId, setItemId ] = useState(null)

  const [ clothingSizeId, setClothingSizeId ] = useState('')
  const [ codeColorId, setCodeColorId ] = useState('')

  const [ onHandQuantity, setOnHandQuantity ] = useState(0)
  const [ outgoingQuantity, setOutgoingQuantity ] = useState(0)
  const [ minQuantity, setMinQuantity ] = useState(0)

  const [ locations, setLocations ] = useState([])
  const [ warehouses, setWarehouses ] = useState([])
  const [ locationOptions, setLocationOptions ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ categoryOptions, setCategoryOptions ] = useState([])

  const [ manufacturers, setManufacturers ] = useState([])
  const [ shortDescription, setShortDescription ] = useState("")
  const [ fullDescription, setFullDescription ] = useState("")

  const [ submitted, setSubmitted ] = useState(false)
  const [ manufacturerLoading, setManufacturerLoading ] = useState(false)
  const [ manufacturerOptions, setManufacturerOptions ] = useState([])
  const [ categoryLoading, setCategoryLoading ] = useState(false)
  const [ locationLoading, setLocationLoading ] = useState(false)
  const [ clothingSizeOptions, setClothingSizeOptions ] = useState([])
  const [ codeColorLoading, setCodeColorLoading ] = useState(false)
  const [ codeColorOptions, setCodeColorOptions ] = useState([])
  const [ purchaseOrderOptions, setPurchaseOrderOptions ] = useState([])

  useEffect(() => {
    if (product) {
      setId(product.id)
      setCode(product.code)
      setName(product.name)
      setSku(product.sku)
      setItemId(product.itemId)
      setPurchaseOrderId(product.purchaseOrderId ? {
        value: product.purchaseOrderId,
        label: product.purchaseOrderCode
      } : null)
      setClothingSizeId(product.clothingSizeId ?
          {
            key: product.clothingSizeId,
            value: product.clothingSizeId,
            label: product.clothingSizeName
          }
          : null)

      setCodeColorId(product.codeColorId ?
          {
            key: product.codeColorId,
            value: product.codeColorId,
            label: product.codeColorName
          }
          : null)

      setOnHandQuantity(product.onHandQuantity)
      setOutgoingQuantity(product.outgoingQuantity)
      setMinQuantity(product.minQuantity)
      setShortDescription(product.shortDescription ?? "")
      setFullDescription(product.fullDescription ?? "")
      setManufacturers(
          product
              ? product.manufacturers
                  ? product.manufacturers.map(a => {
                    return {
                      key: a.id,
                      value: a.id,
                      label: a.name,
                    }
                  })
                  : []
              : []
      )
      setCategories(
          product.categories ?
              product.categories.map(a => {
                return {
                  key: a.id,
                  value: a.id,
                  label: a.name,
                }
              }) : []
      )
      setLocations(
          product.locations.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
      setWarehouses(
          product.warehouses.map(a =>{
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    }
  }, [ product ])

  useEffect(() => {
    getAllPurchaseOrdersRequest().then(data => {
      if (data) {
        setPurchaseOrderOptions(data.map(a => {
          return {
            key: a.id,
            value: a.id,
            label: a.code
          }
        }))
      }
    })

    manufacturerService.getRequest().then(data => {
      if (data) {
        setManufacturerOptions(
            data.map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.name,
              }
            })
        )
      }
    })

    locationService.getRequest().then(data => {
      setLocationOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })

    categoryService.getRequest().then(data => {
      setCategoryOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })

    codeColorService.getRequest().then(data => {
      setCodeColorOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.code,
            }
          })
      )
    })

    getAllClothingSizesRequest().then(data => {
      if (data) {
        setClothingSizeOptions(data.map(a => {
          return {
            key: a.id,
            value: a.id,
            label: a.name
          }
        }))
      }
    })
  }, [])

  const handleManufaturerChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true)
      setManufacturerLoading(true)
      manufacturerService
          .createRequest({ name: newItem.value })
          .then(data => {
            if (data) {
              setManufacturerLoading(false)
              setManufacturers(
                  manufacturers.concat({
                    key: data.id,
                    value: data.id,
                    label: data.name,
                  })
              )
            }
          })
          .catch(err => {
            console.log(err)
            setManufacturerLoading(false)
          })
    } else {
      setManufacturers(newValue)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    let isValid = code && warehouses && warehouses.length > 0
    if (isValid) {
      const data = {
        id,
        code,
        name,
        sku,
        itemId,
        purchaseOrderId: purchaseOrderId?.value ?? null,
        clothingSizeId: clothingSizeId?.value,
        codeColorId: codeColorId?.value,
        onHandQuantity,
        outgoingQuantity,
        minQuantity,
        shortDescription,
        fullDescription,
        manufacturers:
            manufacturers &&
            manufacturers.map(a => {
              return {
                id: a.value,
                name: a.label,
              }
            }),
        categories:
            categories &&
            categories.map(a => {
              return {
                id: a.value,
                name: a.label,
              }
            }),
        locations:
            locations &&
            locations.map(a => {
              return {
                id: a.value,
                name: a.label,
                type: a.label,
              }
            }),
        warehouses:
            warehouses &&
            warehouses.map(a => {
              return {
                id: a.value,
                name: a.label,
              }
            }),
      }

      onSubmit(data)
    }
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleLocationChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true)
      setLocationLoading(true)
      locationService
          .createRequest({ name: newItem.value, type: "Item Location" })
          .then(data => {
            if (data) {
              setLocationLoading(false)
              setLocations(
                  locations.concat({
                    key: data.id,
                    value: data.id,
                    label: data.name,
                  })
              )
            }
          })
          .catch(err => {
            console.log(err)
            setLocationLoading(false)
          })
    } else {
      setLocations(newValue)
    }
  }

  const handleCategoryChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true)
      setCategoryLoading(true)
      categoryService
          .createRequest({ name: newItem.value })
          .then(data => {
            if (data) {
              setCategoryLoading(false)
              setCategories(
                  categories.concat({
                    key: data.id,
                    value: data.id,
                    label: data.name,
                  })
              )
            }
          })
          .catch(err => {
            console.log(err)
            setCategoryLoading(false)
          })
    } else {
      setCategories(newValue)
    }
  }

  const handleCodeColorChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {

      setCodeColorLoading(true)
      codeColorService
          .createRequest({ code: newValue.value })
          .then(data => {
            if (data) {
              setCodeColorLoading(false)
              setCodeColorId({
                key: data.id,
                value: data.id,
                label: data.code
              })

              setCodeColorOptions(codeColorOptions.concat({
                key: data.id,
                value: data.id,
                label: data.code
              }))
            }
          })
          .catch(err => {
            console.log(err)
            setCodeColorLoading(false)
          })
    } else {
      setCodeColorId(newValue)
    }
  }

  return (
      <Row>
        <Col md={ 12 }>
          <Card className="mb-2">
            <CardBody>
              <Row>
                <Col md={ 4 }>
                  <FormGroup className="mb-3">
                    <div
                        className={ submitted && isEmpty(name) ? "is-invalid" : "" }
                    >
                      <Label>Code</Label>
                      <Input
                          type="text"
                          id="code"
                          name="code"
                          placeholder="Code"
                          className={ submitted && isEmpty(name) ? "is-invalid" : "" }
                          value={ code }
                          onChange={ e => {
                            setCode(e.target.value)
                          } }
                      />
                    </div>
                    { submitted && isEmpty(code) && (
                        <div className="invalid-feedback-custom">
                          Code is required.
                        </div>
                    ) }
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Name</Label>
                    <Input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={ name }
                        onChange={ e => {
                          setName(e.target.value)
                        } }
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Category</Label>

                    <CreatableSelect
                        name="categories"
                        value={ categories }
                        onChange={ handleCategoryChange }
                        options={ categoryOptions }
                        classNamePrefix="test select2-selection"
                        isLoading={ categoryLoading }
                        isClearable
                        isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md={ 4 }>
                  <div className="mb-3">
                    <div
                        className={
                            "select2-container" +
                            (submitted && warehouses.length === 0 ? " is-invalid" : "")
                        }
                    >
                    <Label>Warehouse</Label>
                    <WarehouseSelect
                        name="warehouses"
                        value={ warehouses}
                        isMulti={ true }
                        onChange={ option => setWarehouses(option) }
                    />
                    </div>
                    { submitted && warehouses.length === 0 && (
                        <div className="invalid-feedback-custom">
                          Warehouse is required.
                        </div>
                    ) }
                  </div>
                  
                  <FormGroup className="mb-3">
                    <Label>Locations</Label>

                    <CreatableSelect
                        name="locations"
                        value={ locations }
                        onChange={ handleLocationChange }
                        options={ locationOptions }
                        classNamePrefix="test select2-selection"
                        isLoading={ locationLoading }
                        isClearable
                        isMulti
                    />
                  </FormGroup>

                  <FormGroup className="mb-3">
                    <Label>Purchase Order</Label>
                    <Select
                        name="purchaseOrderId"
                        value={ purchaseOrderId }
                        onChange={ option => {
                          setPurchaseOrderId(option);
                        } }
                        options={ purchaseOrderOptions }
                        classNamePrefix="select2-selection"
                        placeholder="Purchase Order"
                        isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={ 4 }>
                  <FormGroup className="mb-3">
                    <Label>Manufacturers</Label>
                    <CreatableSelect
                        name="manufacturers"
                        value={ manufacturers }
                        onChange={ handleManufaturerChange }
                        options={ manufacturerOptions }
                        classNamePrefix="test select2-selection"
                        isLoading={ manufacturerLoading }
                        isClearable
                        isMulti
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Clothing Size</Label>
                    <Select
                        name="clothingSizeId"
                        value={ clothingSizeId }
                        onChange={ option => setClothingSizeId(option) }
                        options={ clothingSizeOptions }
                        classNamePrefix="select2-selection"
                        placeholder="Clothing Size"
                        isClearable
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Code Color</Label>
                    <CreatableSelect
                        name="codeColorId"
                        value={ codeColorId }
                        onChange={ handleCodeColorChange }
                        options={ codeColorOptions }
                        classNamePrefix="test select2-selection"
                        isLoading={ codeColorLoading }
                        isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={ 12 }>
                  <FormGroup className="mb-3">
                    <Label>Short Description</Label>
                    <Input
                        type="textarea"
                        id="nashortDescriptionme"
                        name="shortDescription"
                        rows={ 3 }
                        value={ shortDescription }
                        onChange={ e => {
                          setShortDescription(e.target.value)
                        } }
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Full Description</Label>
                    <Input
                        type="textarea"
                        id="fullDescription"
                        name="fullDescription"
                        rows={ 3 }
                        value={ fullDescription }
                        onChange={ e => {
                          setFullDescription(e.target.value)
                        } }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <Row>
                <Col md={ 4 }>
                  <FormGroup className="mb-3">
                    <Label>On Hand Quantity</Label>
                    <Input
                        type="text"
                        id="onHandQuantity"
                        name="onHandQuantity"
                        placeholder="On Hand Quantity"
                        value={ onHandQuantity }
                        readOnly={ true }
                    />
                  </FormGroup>
                </Col>
                <Col md={ 4 }>
                  <FormGroup className="mb-3">
                    <Label>Out Going Quantity</Label>
                    <Input
                        type="text"
                        id="outgoingQuantity"
                        name="outgoingQuantity"
                        placeholder="Out Going Quantity"
                        value={ outgoingQuantity }
                        readOnly={ true }
                    />
                  </FormGroup>
                </Col>
                <Col md={ 4 }>
                  { " " }
                  <FormGroup className="mb-3">
                    <Label>Min Quantity</Label>
                    <Input
                        type="number"
                        id="minQuantity"
                        name="minQuantity"
                        placeholder="Min Quantity"
                        value={ minQuantity }
                        onChange={ e => setMinQuantity(e.target.value) }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Col md={ 12 }>
            <Card>
              <CardBody>
                <div className="float-end">
                  { hasAccess(permission.product.write) &&
                      <Button color="primary" onClick={ handleSubmit } className={ "me-2" }>
                        Submit{ " " }
                      </Button>
                  }
                  <Button color="primary" onClick={ handleCancel } outline>
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Col>
      </Row>
  )
}

ProductForm.propTypes = {
  product: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

export default ProductForm
