import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { customSingleValue, formatItemOptionLabel } from "../../helpers/utils";
import Select from "react-select";
import { getProductDetailRequest, getProductRequest } from "../../store/product/saga";
import { debounce } from "lodash";
import { NIL } from "uuid";

const ProductSelect = ({ name, warehouseId, placeholder, className, onSelect }) => {
  const [ term, setTerm ] = useState('')
  const [ itemOptions, setItemOptions ] = useState([])
  const [ loading, setLoading ] = useState(false)

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )

  const handleInputChange = (value) => {
    debouncedQuickSearch(value)
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    if (valueType) {
      const { value, label } = valueType

      getProductDetailRequest(
          {
            productId: value,
            warehouseIds: warehouseId ? [ warehouseId ] : []
          }).then(data => {
        const { productUnitOfMeasurements, stockLocations } = data;

        let locationOptions = stockLocations?.map(a => {
          return {
            value: a.locationId,
            label: a.locationName
          }
        }) ?? []

        let uomOptions = productUnitOfMeasurements?.map(a => {
          return {
            value: a.unitOfMeasurementId,
            label: a.unitOfMeasurementName
          }
        }) ?? []


        let newEntry = {
          // index: max,
          id: NIL,
          productId: valueType,
          label: label,
          quantity: 1,
          locationId: locationOptions.length > 0 ? locationOptions[0] : null,
          unitOfMeasurementId: uomOptions.length > 0 ? uomOptions[0] : null,
          uomOptions,
          locationOptions,
          isSelected: false
        }

        onSelect(newEntry)
      });

    }
  }
  
  useEffect(() => {
    setLoading(true)

    if (warehouseId) {
      getProductRequest({
        term,
        page: 1, pageSize: 20,
        warehouseIds: warehouseId ? [ warehouseId ] : []
      }).then(res => {
        if (res.data) {
          setLoading(false)
          setItemOptions(res.data.map((a, index) => {
            return {
              key: a.id,
              value: a.id,
              type: a.type,
              itemAttachment: a.productImages.length > 0 ? a.productImages[0] : null,
              quantity: a.onHandQuantity,
              label: `${ a.code } - ${ a.name }`
            }
          }))
        }
      })
    }
  }, [ term, warehouseId ])

  return (
      <Select
          name={ name }
          value={ null }
          placeholder={ placeholder }
          onChange={ handleSelectChange }
          className={ className }
          options={ itemOptions }
          components={ {
            SingleValue: customSingleValue
          } }
          onInputChange={ handleInputChange }
          formatOptionLabel={ formatItemOptionLabel }
          classNamePrefix="select2-selection"
          isLoading={ loading }
          isClearable
      />
  );
};

ProductSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  warehouseId: PropTypes.string,
  className: PropTypes.string,
  onSelect: PropTypes.func,
};

export default ProductSelect;