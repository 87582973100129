import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col, DropdownItem, DropdownMenu, DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown
} from "reactstrap"
import { NIL } from "uuid"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { getMaxValue } from "../../helpers/utils"
import StockOutItemRow from "./StockOutItemRow"
import moment from "moment/moment"
import StockOutConfirmModal from "./StockOutConfirmModal"
import StockOutValidateResultModal from "./StockOutValidateResultModal"
import { validateStockOutRequest } from "../../store/stock-out/saga"
import PrintPreviewModal from "./PrintPreviewModal"
import { getTransferLocationDropdownRequest } from "../../store/transfer-location/saga"
import WarehouseSelect from "../warehouse/WarehouseSelect";
import ItemSelect from "../item/ItemSelect";

const StockOutForm = props => {
  const { item, onSubmit, onCancel } = props
  
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ modalValidateResult, setModalValidateResult ] = useState(false)
  const [ resultValidationData, setResultValidationData ] = useState([])
  const [ modalPrintPreview, setModalPrintPreview ] = useState(false);
  const [ transferLocations, setTransferLocations ] = useState([])
  const [ isPrintForWarehouse, setIsPrintForWarehouse ] = useState(false)
  
// setStockOut
  const [ stockOut, setStockOut ] = useState({
    id: NIL,
    code: '',
    name: '',
    serialNumber: '',
    status: 0,
    fromId: null,
    toId: null,
    date: new Date(),
    stockOutItems: []
  })

  const [ isSubmitted, setIsSubmitted ] = useState(false)
  
  useEffect(() => {
    getTransferLocationDropdownRequest().then(data => {
      setTransferLocations(data)
    })
  }, [])

  useEffect(() => {
    if (item) {
      setStockOut({
        ...item,
        date: moment(item.date).toDate(),
        warehouseId: item.warehouseId ? {
          id: item.warehouseId,
          value: item.warehouseId,
          label: item.warehouseName
        } : null,
        fromId: item.fromId ? {
          key: item.fromId,
          value: item.fromId,
          label: item.fromName
        } : null,
        toId: item.toId ? {
          key: item.toId,
          value: item.toId,
          label: item.toName
        } : null,
        stockOutItems: item.stockOutItems.map((a, index) => {
          return {
            ...a,
            index,
            label: `${ a.itemCode } - ${ a.itemName }`,
            itemId: {
              key: a.itemId,
              value: a.itemId,
            },
            unitOfMeasurementId: {
              id: a.itemUnitOfMeasurementId,
              value: a.unitOfMeasurementId,
              locationId: a.locationId,
              label: `${a.itemUnitOfMeasurementTitle} - ${a.locationName}`
            }
          }
        })
      })

    } else {
      setStockOut({
        id: NIL,
        code: '',
        name: '',
        warehouseId: null,
        date: new Date(),
        serialNumber: '',
        status: 0,
        stockOutItems: []
      })
    }
  }, [ item ])
  

  const handleSelectItem = (product) => {
    let max = 0
    if (stockOut.stockOutItems.length > 0) {
      max = getMaxValue(
          stockOut.stockOutItems.map(a => {
            return a.index
          })
      )
      max += 1
    } else {
      max = max + 1
    }

    let newEntry = {
      ...product,
      index: max,
    }

    setStockOut({ ...stockOut, stockOutItems: [ ...stockOut.stockOutItems, newEntry ] })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    switch (name) {
      default:
        setStockOut({
          ...stockOut,
          [name]: valueType
        })
        break
    }
  }

  const handleDeleteSelected = () => {
    setStockOut({ ...stockOut, stockOutItems: stockOut.stockOutItems.filter(e => !e.isSelected) })
    setIsHeaderSelected(false)
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setStockOut({
      ...stockOut,
      stockOutItems: stockOut.stockOutItems.map(entry => {
        return {
          ...entry,
          isSelected: checked
        }
      })
    })
  }

  const handleStockInItemChange = entry => {
    setStockOut({
      ...stockOut,
      stockOutItems: stockOut.stockOutItems.map(a => a.index === entry.index ? entry : a)
    })
  }

  const handleDeleteStockInItem = (entry) => {
    setStockOut({
      ...stockOut,
      stockOutItems: stockOut.stockOutItems.filter(e => e.index !== entry.index)
    })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setStockOut({
      ...stockOut,
      [name]: value
    })
  }

  const handleOnDateChange = (name, value) => {
    setStockOut({
      ...stockOut,
      [name]: value
    })
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    let isValid = stockOut.date && stockOut.name && stockOut.warehouseId
    let hasNull = stockOut.stockOutItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && stockOut.stockOutItems.length > 0) {
      let data = {
        ...stockOut,
        warehouseId: stockOut.warehouseId?.value,
        date: stockOut.date?.toISOString(),
        fromId: stockOut.fromId?.value,
        toId: stockOut.toId?.value,
        stockOutItems: stockOut.stockOutItems.map(entry => {
          return {
            ...entry,
            itemId: entry.itemId?.value,
            itemUnitOfMeasurementId: entry.unitOfMeasurementId?.id,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value,
            locationId: entry.unitOfMeasurementId?.locationId,
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleSubmitStockConfirmation = () => {
    setModalConfirmStock(false)
    setIsSubmitted(true)

    let isValid = stockOut.date && stockOut.name && stockOut.warehouseId;
    let hasNull = stockOut.stockOutItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && stockOut.stockOutItems.length > 0) {
      let data = {
        ...stockOut,
        status: 1,
        warehouseId: stockOut.warehouseId?.value,
        date: stockOut.date?.toISOString(),
        fromId: stockOut.fromId?.value,
        toId: stockOut.toId?.value,
        stockOutItems: stockOut.stockOutItems.map(entry => {
          return {
            ...entry,
            itemId: entry.itemId?.value,
            itemUnitOfMeasurementId: entry.unitOfMeasurementId?.id,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value,
            locationId: entry.unitOfMeasurementId?.locationId,
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleValidateStockOut = () => {
    validateStockOutRequest(stockOut.id).then(res => {
      if (res) {
        const { hasInvalid, results } = res

        if (hasInvalid) {
          setModalValidateResult(hasInvalid)
          setResultValidationData(results)
        } else {
          setModalConfirmStock(true)
        }
      }
    })
  }
  
  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col className={ "text-end" }>
                {
                  stockOut.id !== NIL ?
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary" outline>
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                              onClick={ () => {
                                setModalPrintPreview(true)
                                setIsPrintForWarehouse(true);
                              }}
                              className="text-primary"
                          >
                            <i className="mdi mdi-printer me-1"/> Print For Warehouse
                          </DropdownItem>
                          <DropdownItem
                              onClick={ () => {setModalPrintPreview(true)
                                setIsPrintForWarehouse(false);
                              }}
                              className="text-primary"
                          >
                            <i className="mdi mdi-printer me-1"/> Print For Customer
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown> : null
                }
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Code</Label>
                  <Input
                      type="text"
                      name="code"
                      placeholder="Auto Generate"
                      value={ stockOut.code }
                      readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Label>From</Label>
                  <Select
                      name="fromId"
                      value={ stockOut.fromId }
                      onChange={ handleSelectChange }
                      options={ transferLocations }
                      classNamePrefix="select2-selection"
                      placeholder="From"
                      isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label>To</Label>
                  <Select
                      name="toId"
                      value={ stockOut.toId }
                      onChange={ handleSelectChange }
                      options={ transferLocations }
                      classNamePrefix="select2-selection"
                      placeholder="To"
                      isClearable
                  />
                </FormGroup>
              </Col>
              <Col>
                <div className="mb-3">
                  <div
                      className={
                          "select2-container" +
                          (isSubmitted && !stockOut.warehouseId ? " is-invalid" : "")
                      }
                  >

                    <Label htmlFor="">Warehouse</Label>
                    <WarehouseSelect value={ stockOut.warehouseId } onChange={ option => {
                      setStockOut({
                        ...stockOut,
                        warehouseId: option
                      })
                    } }/>
                  </div>
                  { isSubmitted && !stockOut.warehouseId && (
                      <div className="invalid-feedback-custom">
                        Warehouse is required.
                      </div>
                  ) }
                </div>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Title</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Title"
                      autoComplete="off"
                      className={ isSubmitted && !stockOut.name ? "is-invalid" : "" }
                      value={ stockOut.name }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !stockOut.name && (
                      <div className="invalid-feedback-custom">Name is required.</div>
                  ) }
                </FormGroup>

              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Serial No</Label>
                  <Input
                      type="text"
                      name="serialNumber"
                      placeholder="Serial No"
                      autoComplete="off"
                      className={ isSubmitted && !stockOut.serialNumber ? "is-invalid text-end" : "text-end" }
                      value={ stockOut.serialNumber }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !stockOut.serialNumber && (
                      <div className="invalid-feedback-custom">Serial No is required.</div>
                  ) }
                </FormGroup>
                <FormGroup
                    className={
                        "select2-container" + (isSubmitted && !stockOut.date ? " is-invalid" : "")
                    }
                >
                  <Label>Stock Out Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsStart
                      name="date"
                      selected={ stockOut.date }
                      onChange={ obj => handleOnDateChange('date', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Stock Out Date"
                      isClearable
                  />
                </FormGroup>
                { isSubmitted && !stockOut.date && (
                    <div className="invalid-feedback-custom">
                      Stock Out Date is required.
                    </div>
                ) }
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
              <Col>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <ItemSelect
                    name="itemId"
                    warehouseId={ stockOut.warehouseId?.value }
                    placeholder="Find by Code, Name, ..."
                    onSelect={ handleSelectItem }
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button color={ "danger" }
                          onClick={ handleDeleteSelected }
                          outline
                          disabled={ !stockOut.stockOutItems.some(e => e.isSelected) }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>

              {
                stockOut.stockOutItems.map((entry, index) => {
                  return <StockOutItemRow
                      key={ index }
                      item={ entry }
                      isSubmitted={ isSubmitted }
                      onChange={ handleStockInItemChange }
                      onDelete={ handleDeleteStockInItem }
                  />
                })
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card className={ "mb-2" }>
          <CardBody>
            {
                [ 0 ].includes(stockOut.status) && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleSubmit }
                        type="submit">
                  Submit
                </Button>
            }
            {
                stockOut.id !== NIL && [ 0 ].includes(stockOut.status) && stockOut.stockOutItems.length > 0 && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleValidateStockOut }
                        type="submit">
                  Confirm Stock Out
                </Button>
            }

            <Button color="primary"
                    outline
                    onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>

        <StockOutConfirmModal
            title="Confirm item stock out"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />

        {
          resultValidationData.length > 0 ?
              <StockOutValidateResultModal
                  title={ "Validate Item Stock Out Result" }
                  isOpen={ modalValidateResult }
                  toggle={ () => setModalValidateResult(!modalValidateResult) }
                  items={ resultValidationData }
              /> : null
        }

        <PrintPreviewModal
            isOpen={ modalPrintPreview }
            stockOutId={ stockOut.id }
            isPrintForWarehouse={ isPrintForWarehouse }
            toggle={ () => setModalPrintPreview(!modalPrintPreview) }
        />
      </>
  )
}

StockOutForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
}

export default StockOutForm