import { get, post, del, put as update } from "helpers/api_helper";

const getPagingRequest = async (params) => {
  return await get("/api/Warehouse/paging", { params });
};


export const warehouseService = {
  getPagingRequest,
};
