import React, { useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import { NIL } from "uuid"
import NumberFormat from "react-number-format"
import { hasAccess } from "../../helpers/utils"
import { permission } from "../../constants/permission"
import LocationSelection from "../location/LocationSelection";
import WarehouseSelect from "../warehouse/WarehouseSelect";

const ModalForm = props => {
  const { isOpen, toggle, productId, title, onSubmit } = props

  const [ item, setItem ] = useState({
    id: NIL,
    locationId: null,
    warehouseId: null,
    locationName: '',
    productId,
    onHand: 0,
    outgoing: 0,
    displayOrder: 0,
    note: '',
  })

  const [ submitted, setSubmitted ] = useState(false)

  const handleOnChange = (e) => {
    const { name, value } = e.target

    setItem({
      ...item,
      [name]: value
    })
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    setItem({
      ...item,
      [name]: valueType
    })
  }

  const onOpened = () => {
    const { item } = props

    if (item) {
      setItem({
        ...item,
        locationId: item.locationId ? {
          key: item.locationId,
          value: item.locationId,
          label: item.locationName,
        } : null,
        warehouseId: item.warehouseId ? {
          key: item.warehouseId,
          value: item.warehouseId,
          label: item.warehouseName
        } : null,
        note: item?.note || ''
      })
    } else {
      setItem({
        id: NIL,
        locationId: null,
        warehouseId: null,
        locationName: '',
        productId,
        displayOrder: 0,
        onHand: 0,
        outgoing: 0,
        note: '',
      })

      setSubmitted(false)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    let isValid = item.productId && item.locationId && item.warehouseId;

    if (isValid) {
      const data = {
        ...item,
        locationId: item.locationId?.value,
        warehouseId: item.warehouseId?.value,
      }

      onSubmit(data)
    }
  }

  return (
      <Modal
          size="md"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col>
              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (submitted && !item.locationId ? " is-invalid" : "")
                    }
                >
                  <Label htmlFor="">Location</Label>
                  <LocationSelection name={ "locationId" } value={ item.locationId } onChange={ handleSelectChange }/>
                </div>
                { submitted && !item.locationId && (
                    <div className="invalid-feedback-custom">
                      Location is required.
                    </div>
                ) }
              </div>

              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (submitted && !item.warehouseId ? " is-invalid" : "")
                    }
                >

                  <Label htmlFor="">Warehouse</Label>
                  <WarehouseSelect name={ "warehouseId" } value={ item.warehouseId } onChange={ handleSelectChange }/>
                </div>
                { submitted && !item.warehouseId && (
                    <div className="invalid-feedback-custom">
                      Warehouse is required.
                    </div>
                ) }
              </div>
            </Col>
          </Row>
          { hasAccess(permission.adjustStock.enable) && (
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="">On Hand</Label>
                    <NumberFormat
                        name="onHand"
                        value={ item.onHand }
                        className={ "form-control text-end" }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          setItem({
                            ...item,
                            onHand: values.floatValue
                          })
                        } }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="">Outgoing</Label>
                    <NumberFormat
                        name="outgoing"
                        value={ item.outgoing }
                        className={ "form-control text-end" }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          setItem({
                            ...item,
                            outgoing: values.floatValue
                          })
                        } }
                    />
                  </FormGroup>
                </Col>
              </Row>
          ) }
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <Label htmlFor="">Display Order</Label>
                <Input
                    type="number"
                    name="displayOrder"
                    placeholder="Display Order"
                    value={ item.displayOrder }
                    onChange={ handleOnChange }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <Label htmlFor="">Note</Label>
                <Input
                    type="textarea"
                    name="note"
                    placeholder="Note"
                    value={ item.note }
                    onChange={ handleOnChange }
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

ModalForm.propTypes = {
  productId: PropTypes.string,
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ModalForm
