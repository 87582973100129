import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container, FormGroup,
  Input,
  Row,
} from "reactstrap";
import {
  // StockIn
  getStockIns,
  addNewStockIn,
  updateStockIn,
  getStockInById,
  deleteStockIn,
  clearStockInError,
  getConfirmStockIn,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";

import { debounce } from "lodash";
import StockInTable from "containers/stock-in/StockInTable";
import ModalForm from "containers/stock-in/ModalForm";
import StockInConfirmModal from "containers/stock-in/StockInConfirmModal";
import WarehouseSelect from "../../containers/warehouse/WarehouseSelect";

const StockInList = props => {
  const [id, setId] = useState(null);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [modalConfirmStock, setModalConfirmStock] = useState(false);
  const [ warehouses, setWarehouses ] = useState([])
  const {
    getStockIns,
    addNewStockIn,
    deleteStockIn,
    updateStockIn,
    clearStockInError,
    getStockInById,
    getConfirmStockIn,
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount,
    history,
  } = props;

  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
    }, 1000),
    []
  );

  useEffect(() => {
    getStockIns({ 
      page, 
      pageSize, 
      term, 
      warehouseIds: warehouses.map(warehouse => warehouse.value) });
  }, [page, pageSize, term, warehouses]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearStockInError();
    }
  }, [message]);

  const handleOnEdit = id => {
    getStockInById(id);
    setModalEditIsOpen(true);
  };

  const handleUpdate = supplier => {
    updateStockIn(supplier);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    history.push(`/stock-in/add-new`);
  };


  const handleSubmit = item => {
    addNewStockIn(item);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteStockIn(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnDetail = id => {
    history.push(`/stock-in/${id}`);
  };

  const handleConfirmStock = id => {
    setId(id);
    setModalConfirmStock(true);
  };

  const handleSubmitStockConfirmation = () => {
    getConfirmStockIn({ stockInId: id, type: "List" });
    setModalConfirmStock(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Stock In | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Stock In" breadcrumbItem="Stock In List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={e => {
                              debouncedFetchData(e.target.value);
                            }}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <WarehouseSelect
                            name="warehouses"
                            placeholder={ "Warehouse" }
                            value={ warehouses }
                            onChange={ option => {
                              setPage(1);
                              setWarehouses(option)
                            } }
                            isMulti={ true }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={4}>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={handleOnAddNew}
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {items && (
                <StockInTable
                  items={items}
                  itemCount={itemCount}
                  page={page}
                  totalPage={pageCount}
                  loading={loading}
                  defaultPageSize={pageSize}
                  onDetails={handleOnDetail}
                  onEdit={handleOnEdit}
                  onConfirm={handleConfirmStock}
                  onConfirmDelete={handleConfirmDelete}
                  onChangePage={page => setPage(page)}
                  onPageSizeChange={pageSize => {
                    setPage(1);
                    setPageSize(pageSize);
                  }}
                />
              )}
              {item && (
                <ModalForm
                  title={"Edit UoM"}
                  item={item}
                  isOpen={modalEditIsOpen}
                  toggle={() => setModalEditIsOpen(!modalEditIsOpen)}
                  onSubmit={handleUpdate}
                />
              )}
              <ModalForm
                title={"Add UoM"}
                isOpen={modalAddIsOpen}
                toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={() =>
                  setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                }
                onSubmit={handleDelete}
              />
              <StockInConfirmModal
                title="Confirm item into stock"
                isOpen={modalConfirmStock}
                toggle={() => setModalConfirmStock(!modalConfirmStock)}
                onSubmit={handleSubmitStockConfirmation}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

StockInList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  history: PropTypes.object,

  getStockIns: PropTypes.func,
  addNewStockIn: PropTypes.func,
  updateStockIn: PropTypes.func,
  getStockInById: PropTypes.func,
  deleteStockIn: PropTypes.func,
  clearStockInError: PropTypes.func,
  getConfirmStockIn: PropTypes.func,
};

const mapStateToProps = ({ stockIn }) => {
  return stockIn;
};

export default withRouter(
  connect(mapStateToProps, {
    getStockIns,
    addNewStockIn,
    updateStockIn,
    getStockInById,
    deleteStockIn,
    clearStockInError,
    getConfirmStockIn,
  })(StockInList)
);
