import CustomPagination from "components/Common/CustomPagination"
import React from "react"
import PropTypes from "prop-types"
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import moment from "moment"

const ProductStockInTable = ({
                               itemCount,
                               page,
                               totalPage,
                               defaultPageSize,
                               items,
                               loading,
                               onDetails,
                               onConfirm,
                               onConfirmDelete,
                               onChangePage,
                               onPageSizeChange,
                             }) => {
  const displayStatus = item => {
    let color = ""
    const { status, statusName } = item
    switch (status) {
      case 0:
        color = "info"
        break
      case 1:
        color = "success"
        break
      case 2:
        color = "dark"
        break
    }
    return (
        <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
          <span>{ statusName }</span>
        </Badge>
    )
  }

  return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            { loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
            ) : (
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                  <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered table-nowrap"
                  >
                    <thead>
                    <tr>
                      <th style={ { width: "80px" } }>#</th>
                      <th style={ { width: "120px" } }>Action</th>
                      <th>Code</th>
                      <th>Title</th>
                      <th>Date</th>
                      <th>Item Count</th>
                      <th>Warehouse</th>
                      <th>Status</th>

                    </tr>
                    </thead>
                    <tbody>
                    { items.map((item, index) => {
                      let num = (page - 1) * defaultPageSize + (index + 1)
                      const { status } = item
                      return (
                          <tr key={ index } onDoubleClick={ () => onDetails(item.id) } style={ { cursor: "pointer" } }>
                            <td style={ { textAlign: "right" } }>{ num }</td>
                            <td>
                              <div className="text-center">
                                {
                                    [ 0 ].includes(item.status) &&
                                    <UncontrolledDropdown>
                                      <DropdownToggle className="card-drop" tag="a">
                                        <i className="mdi mdi-dots-horizontal font-size-18"/>
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-start">
                                        {
                                            [ 0 ].includes(item.status) && // Draft
                                            <DropdownItem
                                                onClick={ () => onDetails(item.id) }
                                            >
                                              <i className="mdi mdi-pencil font-size-16 text-primary me-1"/>{ " " }
                                              Edit
                                            </DropdownItem>
                                        }
                                        { status !== 1 &&
                                            item.productStockInProductsCount > 0 && (
                                                <DropdownItem
                                                    onClick={ () => onConfirm(item.id) }
                                                >
                                                  <i className="mdi mdi-check-bold font-size-16 text-primary me-1"/>{ " " }
                                                  Confirm Into Stock
                                                </DropdownItem>
                                            ) }
                                        {
                                            [ 0 ].includes(item.status) && // Draft
                                            <DropdownItem
                                                onClick={ () => onConfirmDelete(item.id) }
                                            >
                                              <i className="mdi mdi-trash-can font-size-16 text-danger me-1"/>{ " " }
                                              Delete
                                            </DropdownItem>
                                        }
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                              </div>
                            </td>
                            <td>{ item.code }</td>
                            <td>{ item.name }</td>
                            <td>
                              { moment(item.date).local().format("DD-MMM-YYYY") }
                            </td>
                            <td className="text-end">
                              { item.productStockInProductsCount }
                            </td>
                            <td>
                              { item.warehouseName }
                            </td>
                            <td>{ displayStatus(item) }</td>

                          </tr>
                      )
                    }) }
                    </tbody>
                  </Table>
                </div>
            ) }
          </div>
          <CustomPagination
              itemCount={ itemCount }
              currentPage={ page }
              totalPage={ totalPage }
              defaultPageSize={ defaultPageSize }
              pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
              onChangePage={ i => onChangePage(i) }
              onPageSizeChange={ size => onPageSizeChange(size) }
          />
        </CardBody>
      </Card>
  )
}

ProductStockInTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onConfirm: PropTypes.func,
  onDetails: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
}

export default ProductStockInTable
