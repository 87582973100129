import React from 'react'
import PropTypes from 'prop-types'
import '../../assets/css/prints.css'
import NumberFormat from "react-number-format"
import moment from "moment"

const StockOutDetailToPrint = React.forwardRef((props, ref) => {
  const { item, isPrintForWarehouse } = props
  const { stockOutItems: products } = item

  return (<div ref={ ref }>
    <div className="print-container">
      <div className="print-content">
        <div className="header-section">
          <h2 className="header-title" style={ { fontSize: "1rem", color: "#000" } }>K&K GARMENT CO.,LTD</h2>
          <p className={ "card-title-desc text-center mb-0" } style={ { fontSize: "10pt", color: "#000" } }>No. 22 St
            02, Sangkat Chak
            Angrer Leu, Khan Mean Chey, Phnom Penh, Cambodia.</p>
          <hr className={ "m-0" }/>
          <h2 className="header-title" style={ { fontSize: "1.1rem", color: "#000" } }>ប័ណ្ណបញ្ចេញទំនិញ</h2>
          <div className="header-section1">
            <div className="header-col">
              { isPrintForWarehouse ?
                  <>
                    <p>Transfer From: <strong>{ item.fromName }</strong></p>
                    <p>Transfer To: <strong>{ item.toName }</strong></p>
                  </> : null
              }
            </div>
            <div className={ "mb-1 header-col text-center" }>
              <h2 className="header-title" style={ { fontSize: "1.1rem", color: "#000" } }>GOODS ISSUED NOTE</h2>
            </div>
            <div className="header-col">
              <p>Serial No: <strong>{ item.serialNumber }</strong></p>
              <p>Date: <strong>{ moment(item.date)
                  .local()
                  .format("DD-MMM-YYYY") }</strong></p>
            </div>
          </div>
        </div>
        <table className="table-print">
          <thead>
          <tr>
            <th>#</th>
            <th>Code</th>
            <th>Name</th>
            <th>Package</th>
            { isPrintForWarehouse ?
                <th>Location</th> : null
            }
            <th>Quantity</th>
            <th>Remark</th>
          </tr>
          </thead>
          <tbody>
          {
            products.map((product, index) => {
              let totalQuantity =
                  product.quantity * product.unitOfMeasurementConversionRate;

              return <tr key={ index }>
                <td className={ "text-right" }>{ index + 1 }</td>
                <td>{ product.itemCode }</td>
                <td>{ product.itemName }</td>
                <td>{ product.unitOfMeasurementName }</td>
                {
                  isPrintForWarehouse ?
                      <td>{ product.locationName }</td> : null
                }
                <td className={ "text-right" }>
                  <NumberFormat
                      value={ totalQuantity ?? 0 }
                      displayType="text"
                      thousandSeparator={ true }
                      fixedDecimalScale={ false }
                  />
                </td>
                <td></td>
              </tr>
            })
          }
          </tbody>
        </table>
        <div className={ "m-0" }>
          <p>Title: <strong>{ item.name }</strong></p>
        </div>
        <div className="footer-section">
          <div className={ "p-2" } style={ { width: "100%", border: "1px", borderStyle: "solid" } }>
            <p className={ "text-center" }>រៀបចំដោយ/Prepared By </p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.createdByUser}</strong></p>*/ }
          </div>
          <div className={ "p-2" } style={ { width: "100%", border: "1px", borderStyle: "solid" } }>
            <p className={ "text-center" }>ត្រួតពិនិត្យដោយ/Verified By </p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.approvedByUser}</strong></p>*/ }
          </div>
          <div className={ "p-2" } style={ { width: "100%", border: "1px", borderStyle: "solid" } }>
            <p className={ "text-center" }>អនុញ្ញាត្តិដោយ/Verified By</p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.createdByUser}</strong></p>*/ }
          </div>
          <div className={ "p-2" } style={ { width: "100%", border: "1px", borderStyle: "solid" } }>
            <p className={ "text-center" }>អ្នកទទួល/Received By </p>
            <div className="box-signature"></div>
            {/*<p><strong>{stockRequest.approvedByUser}</strong></p>*/ }
          </div>
        </div>
      </div>
    </div>
  </div>)
})

StockOutDetailToPrint.displayName = 'StockOutDetailToPrint'

StockOutDetailToPrint.propTypes = {
  item: PropTypes.object,
  isPrintForWarehouse: PropTypes.bool,
}

export default StockOutDetailToPrint