import React, { useEffect, useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import { NIL } from "uuid"
import { getItemTypeOptions, hasAccess } from "helpers/utils"
import { locationService } from "services/location-service"
import { unitOfMeasurmentService } from "../../services/unit-of-measurement-service"
import { categoryService } from "../../services/category-service"
import NumberFormat from "react-number-format"
import { permission } from "../../constants/permission"
import WarehouseSelect from "../warehouse/WarehouseSelect";

const ModalForm = ({ isOpen, toggle, title, item, onSubmit }) => {
  const [ id, setId ] = useState(NIL)
  const [ code, setCode ] = useState("")
  const [ name, setName ] = useState("")
  const [ type, setType ] = useState(null)
  const [ onHandQuantity, setOnHandQuantity ] = useState(0)
  const [ outgoingQuantity, setOutgoingQuantity ] = useState(0)
  const [ minQuantity, setMinQuantity ] = useState(0)
  const [ submitted, setSubmitted ] = useState(false)
  const [ locations, setLocations ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ warehouses, setWarehouses ] = useState([])

  const [ locationOptions, setLocationOptions ] = useState([])
  const [ locationLoading, setLocationLoading ] = useState(false)
  const [ unitOfMeasurementId, setUnitOfMeasurementId ] = useState(null)
  const [ unitOfMeasurementOptions, setUnitOfMeasurementOptions ] = useState([])
  const [ categoryOptions, setCategoryOptions ] = useState([])
  const [ categoryLoading, setCategoryLoading ] = useState()

  useEffect(() => {
    categoryService.getRequest().then(data => {
      if (data) {
        setCategoryOptions(data
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.name,
              }
            }),
        )
      }
    })

    locationService.getRequest().then(data => {
      setLocationOptions(
          data.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })

    unitOfMeasurmentService.getAllRequest({ pageSize: 100 }).then(res => {
      const { data } = res

      setUnitOfMeasurementOptions(
          data.map(a => {
            return {
              id: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    })

  }, [])

  const onOpened = () => {
    if (item) {
      const {
        id,
        code,
        name,
        onHandQuantity,
        outgoingQuantity,
        minQuantity,
        type,
      } = item
      setId(id)
      setCode(code)
      setName(name)
      setOnHandQuantity(onHandQuantity)
      setOutgoingQuantity(outgoingQuantity)
      setMinQuantity(minQuantity)
      setType(getItemTypeOptions().find(e => e.value === type))
      setLocations(
          item.locations.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )

      setWarehouses(
          item.warehouses.map(a =>{
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )

      setCategories(
          item.categories.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: a.name,
            }
          })
      )
    } else {
      setId(NIL)
      setCode("")
      setName("")
      setUnitOfMeasurementId(null)

      setOnHandQuantity(0)
      setOutgoingQuantity(0)
      setMinQuantity(0)
      setType(null)
      setSubmitted(false)
    }
  }

  const handleSubmit = () => {
    setSubmitted(true)
    const data = {
      id,
      code,
      name,
      onHandQuantity,
      outgoingQuantity,
      minQuantity,
      unitOfMeasurementId: unitOfMeasurementId?.value,
      locations:
          locations &&
          locations.map(a => {
            return {
              id: a.value,
              name: a.label,
              type: a.label,
            }
          }),
      warehouses:
          warehouses &&
          warehouses.map(a => {
            return {
              id: a.value,
              name: a.label,
            }
          }),
      categories:
          categories &&
          categories.map(a => {
            return {
              id: a.value,
              name: a.label
            }
          }),
      type: type?.value,
    }

    if (data.code && data.name && data.type) {
      onSubmit(data)
    }
  }

  const handleLocationChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true)
      setLocationLoading(true)
      locationService
          .createRequest({ name: newItem.value, type: "Item Location" })
          .then(data => {
            if (data) {
              setLocationLoading(false)
              setLocations(
                  locations.concat({
                    key: data.id,
                    value: data.id,
                    label: data.name,
                  })
              )
            }
          })
          .catch(err => {
            console.log(err)
            setLocationLoading(false)
          })
    } else {
      setLocations(newValue)
    }
  }

  const handleCategoryChange = (newValue, actionMeta) => {
    const { action } = actionMeta
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true)
      setCategoryLoading(true)
      categoryService
          .createRequest({ name: newItem.value })
          .then(data => {
            if (data) {
              setCategoryLoading(false)
              setCategories(
                  categories.concat({
                    key: data.id,
                    value: data.id,
                    label: data.name,
                  })
              )
            }
          })
          .catch(err => {
            console.log(err)
            setCategoryLoading(false)
          })
    } else {
      setCategories(newValue)
    }
  }

  return (
      <Modal
          size="lg"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <Label htmlFor="">Code</Label>
                <Input
                    type="text"
                    name="code"
                    placeholder="Code"
                    className={ submitted && !code ? "is-invalid" : "" }
                    value={ code }
                    onChange={ e => {
                      setCode(e.target.value)
                    } }
                />
                { submitted && !code && (
                    <div className="invalid-feedback-custom">Code is required.</div>
                ) }
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="">Name</Label>
                <Input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className={ submitted && !name ? "is-invalid" : "" }
                    value={ name }
                    onChange={ e => {
                      setName(e.target.value)
                    } }
                />
                { submitted && !name && (
                    <div className="invalid-feedback-custom">Name is required.</div>
                ) }
              </FormGroup>


            </Col>
            <Col>
              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (submitted && !type ? " is-invalid" : "")
                    }
                >
                  <Label>Type</Label>
                  <Select
                      name="type"
                      value={ type }
                      onChange={ option => {
                        setType(option)
                      } }
                      options={ getItemTypeOptions() }
                      classNamePrefix="select2-selection"
                      isClearable
                  />
                </div>
                { submitted && !type && (
                    <div className="invalid-feedback-custom">Type is required.</div>
                ) }
              </div>

              {
                !item ?
                    <div className="mb-3">
                      <div
                          className={ "select2-container" }
                      >
                        <Label>Unit of Measurement</Label>
                        <Select
                            name="unitOfMeasurementId"
                            value={ unitOfMeasurementId }
                            onChange={ option => {
                              setUnitOfMeasurementId(option)
                            } }
                            options={ unitOfMeasurementOptions }
                            classNamePrefix="select2-selection"
                            isClearable
                        />
                      </div>
                    </div> : null
              }


            </Col>
          </Row>
          { hasAccess(permission.adjustStock.enable) && (
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="">On Hand</Label>
                    <NumberFormat
                        name="onHandQuantity"
                        value={ onHandQuantity }
                        className={ "form-control text-end" }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          setOnHandQuantity(values.floatValue)

                        } }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="">Outgoing</Label>
                    <NumberFormat
                        name="outgoingQuantity"
                        value={ outgoingQuantity }
                        className={ "form-control text-end" }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          setOutgoingQuantity(values.floatValue)
                        } }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="">Min Quantity</Label>
                    <NumberFormat
                        name="minQuantity"
                        value={ minQuantity }
                        className={ "form-control text-end" }
                        thousandSeparator={ true }
                        fixedDecimalScale={ false }
                        onValueChange={ values => {
                          values.floatValue = parseFloat(values.value)
                          setMinQuantity(values.floatValue)
                        } }
                    />
                  </FormGroup>
                </Col>
              </Row>
          ) }
          <Row>
            <Col>
              <div className="mb-3">
                <div
                    className={
                        "select2-container" +
                        (submitted && warehouses.length === 0 ? " is-invalid" : "")
                    }
                >
                  <Label>Warehouse</Label>
                  <WarehouseSelect
                      name="warehouses"
                      value={ warehouses }
                      isMulti={ true }
                      onChange={ option => setWarehouses(option) }
                  />
                </div>
                { submitted && warehouses.length === 0 && (
                    <div className="invalid-feedback-custom">
                      Warehouse is required.
                    </div>
                ) }
              </div>
            </Col>
            <Col>
              <FormGroup className="mb-3">
                <Label>Locations</Label>
                <CreatableSelect
                    name="locations"
                    value={ locations }
                    onChange={ handleLocationChange }
                    options={ locationOptions }
                    classNamePrefix="test select2-selection"
                    isLoading={ locationLoading }
                    isClearable
                    isMulti
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <Label>Category</Label>
                <CreatableSelect
                    name="categories"
                    value={ categories }
                    onChange={ handleCategoryChange }
                    options={ categoryOptions }
                    classNamePrefix="test select2-selection"
                    isLoading={ categoryLoading }
                    isClearable
                    isMulti
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          { hasAccess(permission.item.write) &&
              <Button color="primary" onClick={ handleSubmit } type="submit">
                Submit
              </Button>
          }
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  )
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ModalForm
