import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col, 
  DropdownItem, 
  DropdownMenu, 
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown
} from "reactstrap"
import { NIL } from "uuid"
import DatePicker from "react-datepicker"
import { getMaxValue } from "../../helpers/utils"
import StockInItemRow from "./StockInItemRow"
import moment from "moment/moment"
import StockInConfirmModal from "./StockInConfirmModal"
import PrintPreviewModal from "./PrintPreviewModal"
import WarehouseSelect from "../warehouse/WarehouseSelect";
import ItemSelect from "../item/ItemSelect";

const StockInForm = props => {
  const { item, onSubmit, onCancel } = props
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ modalPrintPreview, setModalPrintPreview ] = useState(false)

  const [ stockIn, setStockIn ] = useState({
    id: NIL,
    code: '',
    name: '',
    status: 0,
    warehouseId: null,
    date: new Date(),
    stockInItems: []
  })

  const [ isSubmitted, setIsSubmitted ] = useState(false)
  
  useEffect(() => {
    if (item) {
      setStockIn({
        ...item,
        date: moment(item.date).toDate(),
        warehouseId: item.warehouseId ? {
          id: item.warehouseId,
          value: item.warehouseId,
          label: item.warehouseName
        } : null,
        stockInItems: item.stockInItems.map((a, index) => {
          return {
            ...a,
            index,
            label: `${ a.itemCode } - ${ a.itemName }`,
            itemId: {
              key: a.itemId,
              value: a.itemId,
            },
            unitOfMeasurementId: {
              id: a.itemUnitOfMeasurementId,
              value: a.unitOfMeasurementId,
              locationId: a.locationId,
              label: `${ a.itemUnitOfMeasurementTitle } - ${ a.locationName }`
            }
          }
        })
      })

    } else {
      setStockIn({
        id: NIL,
        code: '',
        name: '',
        warehouseId: null,
        date: new Date(),
        status: 0,
        stockInItems: []
      })
    }
  }, [ item ])

  const handleDeleteSelected = () => {
    setStockIn({ ...stockIn, stockInItems: stockIn.stockInItems.filter(e => !e.isSelected) })
    setIsHeaderSelected(false)
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setStockIn({
      ...stockIn,
      stockInItems: stockIn.stockInItems.map(entry => {
        return {
          ...entry,
          isSelected: checked
        }
      })
    })
  }

  const handleStockInItemChange = entry => {
    setStockIn({
      ...stockIn,
      stockInItems: stockIn.stockInItems.map(a => a.index === entry.index ? entry : a)
    })
  }

  const handleDeleteStockInItem = (entry) => {
    setStockIn({
      ...stockIn,
      stockInItems: stockIn.stockInItems.filter(e => e.index !== entry.index)
    })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setStockIn({
      ...stockIn,
      [name]: value
    })
  }

  const handleOnDateChange = (name, value) => {
    setStockIn({
      ...stockIn,
      [name]: value
    })
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    let isValid = stockIn.date && stockIn.name && stockIn.warehouseId;
    let hasNull = stockIn.stockInItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && stockIn.stockInItems.length > 0) {
      let data = {
        ...stockIn,
        date: stockIn.date?.toISOString(),
        warehouseId: stockIn.warehouseId?.value,
        stockInItems: stockIn.stockInItems.map(entry => {
          return {
            ...entry,
            itemId: entry.itemId?.value,
            itemUnitOfMeasurementId: entry.unitOfMeasurementId?.id,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value,
            locationId: entry.unitOfMeasurementId?.locationId,
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleSubmitStockConfirmation = () => {
    setModalConfirmStock(false)
    setIsSubmitted(true)
    let isValid = stockIn.date && stockIn.name && stockIn.warehouseId;
    let hasNull = stockIn.stockInItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && stockIn.stockInItems.length > 0) {
      let data = {
        ...stockIn,
        status: 1,
        warehouseId: stockIn.warehouseId?.value,
        date: stockIn.date?.toISOString(),
        stockInItems: stockIn.stockInItems.map(entry => {
          return {
            ...entry,
            itemId: entry.itemId?.value,
            itemUnitOfMeasurementId: entry.unitOfMeasurementId?.id,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value,
            locationId: entry.unitOfMeasurementId?.locationId,
          }
        })
      }

      onSubmit(data)
    }
  }
  
  const handleSelectItem = (product) => {
    let max = 0
    if (stockIn.stockInItems.length > 0) {
      max = getMaxValue(
          stockIn.stockInItems.map(a => {
            return a.index
          })
      )
      max += 1
    } else {
      max = max + 1
    }
    
    let newEntry = {
      ...product,
      index: max,
    }

    setStockIn({ ...stockIn, stockInItems: [ ...stockIn.stockInItems, newEntry ] })
  }

  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col className={ "text-end" }>
                {
                  stockIn.id !== NIL ?
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary" outline>
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                              onClick={ () => setModalPrintPreview(true) }
                              className="text-primary"
                          >
                            <i className="mdi mdi-printer me-1"/> Print Preview
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown> : null
                }
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <div className="mb-3">
                  <div
                      className={
                          "select2-container" +
                          (isSubmitted && !stockIn.warehouseId ? " is-invalid" : "")
                      }
                  >

                    <Label htmlFor="">Warehouse</Label>
                    <WarehouseSelect value={ stockIn.warehouseId } onChange={ option => {
                      setStockIn({
                        ...stockIn,
                        warehouseId: option
                      })
                    } }/>
                  </div>
                  { isSubmitted && !stockIn.warehouseId && (
                      <div className="invalid-feedback-custom">
                        Warehouse is required.
                      </div>
                  ) }
                </div>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Code</Label>
                  <Input
                      type="text"
                      name="code"
                      placeholder="Auto Generate"
                      value={ stockIn.code }
                      readOnly
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Title</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Title"
                      autoComplete="off"
                      className={ isSubmitted && !stockIn.name ? "is-invalid" : "" }
                      value={ stockIn.name }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !stockIn.name && (
                      <div className="invalid-feedback-custom">Name is required.</div>
                  ) }
                </FormGroup>
              </Col>
              <Col>
                <FormGroup
                    className={
                        "select2-container" + (isSubmitted && !stockIn.date ? " is-invalid" : "")
                    }
                >
                  <Label>Stock In Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsStart
                      name="date"
                      selected={ stockIn.date }
                      onChange={ obj => handleOnDateChange('date', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Stock In Date"
                      isClearable
                  />
                </FormGroup>
                { isSubmitted && !stockIn.date && (
                    <div className="invalid-feedback-custom">
                      Stock In Date is required.
                    </div>
                ) }
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
              <Col>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <ItemSelect
                    name="itemId"
                    warehouseId={ stockIn.warehouseId?.value }
                    placeholder="Find by Code, Name, ..."
                    onSelect={ handleSelectItem }
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button color={ "danger" }
                          onClick={ handleDeleteSelected }
                          outline
                          disabled={ !stockIn.stockInItems.some(e => e.isSelected) }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>

              {
                stockIn.stockInItems.map((entry, index) => {
                  return <StockInItemRow
                      key={ index }
                      item={ entry }
                      isSubmitted={ isSubmitted }
                      onChange={ handleStockInItemChange }
                      onDelete={ handleDeleteStockInItem }
                  />
                })
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card className={ "mb-2" }>
          <CardBody>
            {
                [ 0 ].includes(stockIn.status) && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleSubmit }
                        type="submit">
                  Submit
                </Button>
            }
            {
                stockIn.id !== NIL && [ 0 ].includes(stockIn.status) && stockIn.stockInItems.length > 0 && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ () => setModalConfirmStock(true) }
                        type="submit">
                  Confirm Into Stock
                </Button>
            }

            <Button color="secondary"
                    onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>

        <StockInConfirmModal
            title="Confirm item into stock"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />

        <PrintPreviewModal
            isOpen={ modalPrintPreview }
            stockInId={ stockIn.id }
            toggle={ () => setModalPrintPreview(!modalPrintPreview) }
        />
      </>
  )
}

StockInForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
}

export default StockInForm