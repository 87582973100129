import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col, 
  DropdownItem, 
  DropdownMenu, 
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown
} from "reactstrap"
import { NIL } from "uuid"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { getMaxValue } from "../../helpers/utils"
import { sumBy } from "lodash"
import ProductStockOutRow from "./ProductStockOutRow"
import moment from "moment/moment"
import ProductStockOutConfirmModal from "./ProductStockOutConfirmModal"
import ProductStockOutValidateResultModal from "./ProductStockOutValidateResultModal"
import { validateBulkProductStockOutRequest } from "../../store/product-stock-out/saga"
import PrintPreviewModal from "./PrintPreviewModal"
import { getTransferLocationDropdownRequest } from "../../store/transfer-location/saga"
import WarehouseSelect from "../warehouse/WarehouseSelect";
import ProductSelect from "../product/ProductSelect";

const ProductStockOutForm = props => {
  const { item, onSubmit, onCancel } = props

  const [ term, setTerm ] = useState('')
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ modalValidateResult, setModalValidateResult ] = useState(false)
  const [ modalPrintPreview, setModalPrintPreview ] = useState(false)
  const [ isPrintForWarehouse, setIsPrintForWarehouse ] = useState(false)
  const [ transferLocations, setTransferLocations ] = useState([])
  const [ resultValidationData, setResultValidationData ] = useState([])
  
  const [ productStockOut, setProductStockOut ] = useState({
    id: NIL,
    code: '',
    name: '',
    serialNumber: '',
    status: 0,
    fromId: null,
    toId: null,
    date: new Date(),
    warehouseId: null,
    productStockOutProducts: []
  })

  const [ isSubmitted, setIsSubmitted ] = useState(false)

  useEffect(() => {
    getTransferLocationDropdownRequest().then(data => {
      setTransferLocations(data)
    })
  }, [])
  

  useEffect(() => {
    if (item) {
      setProductStockOut({
        ...item,
        date: moment(item.date).toDate(),
        warehouseId: item.warehouseId ? {
          id: item.warehouseId,
          value: item.warehouseId,
          label: item.warehouseName
        } : null,
        fromId: item.fromId ? {
          key: item.fromId,
          value: item.fromId,
          label: item.fromName
        } : null,
        toId: item.toId ? {
          key: item.toId,
          value: item.toId,
          label: item.toName
        } : null,
        productStockOutProducts: item.productStockOutProducts ? item.productStockOutProducts.map((a, index) => {
          return {
            ...a,
            index,
            label: `${ a.productCode } - ${ a.productName }`,
            productId: {
              key: a.productId,
              value: a.productId,
            },
            locationId: a.locationId ? {
              key: a.locationId,
              value: a.locationId,
              label: a.locationName
            }: null,
            unitOfMeasurementId: {
              key: a.unitOfMeasurementId,
              value: a.unitOfMeasurementId,
              label: a.unitOfMeasurementName
            }
          }
        }) : [],
      })

    } else {
      setProductStockOut({
        id: NIL,
        code: '',
        name: '',
        serialNumber: '',
        date: new Date(),
        status: 0,
        fromId: null,
        toId: null,
        warehouseId: null,
        productStockOutProducts: []
      })
    }
  }, [ item ])

  const handleOnSelectProduct =(product) =>{
    let max = 0;

    if (productStockOut.productStockOutProducts.length > 0) {
      max = getMaxValue(productStockOut.productStockOutProducts.map(a => a.index));
      max += 1;
    } else {
      max = max + 1;
    }
    let newEntry={
      ...product,
      index: max,
    }

    setProductStockOut({ ...productStockOut, productStockOutProducts: [...productStockOut.productStockOutProducts, newEntry] });
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta
    
    setProductStockOut({
      ...productStockOut,
      [name]: valueType
    })
  }

  const handleDeleteSelected = () => {
    setProductStockOut({
      ...productStockOut,
      productStockOutProducts: productStockOut.productStockOutProducts.filter(e => !e.isSelected)
    })
    setIsHeaderSelected(false)
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setProductStockOut({
      ...productStockOut,
      productStockOutProducts: productStockOut.productStockOutProducts.map(entry => {
        return {
          ...entry,
          isSelected: checked
        }
      })
    })
  }

  const handleStockInItemChange = entry => {
    setProductStockOut({
      ...productStockOut,
      productStockOutProducts: productStockOut.productStockOutProducts.map(a => a.index === entry.index ? entry : a)
    })
  }

  const handleDeleteStockInItem = (entry) => {
    setProductStockOut({
      ...productStockOut,
      productStockOutProducts: productStockOut.productStockOutProducts.filter(e => e.index !== entry.index)
    })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setProductStockOut({
      ...productStockOut,
      [name]: value
    })
  }

  const handleOnDateChange = (name, value) => {
    setProductStockOut({
      ...productStockOut,
      [name]: value
    })
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    let isValid = productStockOut.warehouseId && productStockOut.date && productStockOut.name
    let hasNull = productStockOut.productStockOutProducts.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid) {
      let data = {
        ...productStockOut,
        date: productStockOut.date?.toISOString(),
        warehouseId: productStockOut.warehouseId?.value,
        fromId: productStockOut.fromId?.value,
        toId: productStockOut.toId?.value,
        productStockOutProducts: productStockOut.productStockOutProducts.map(entry => {
          return {
            ...entry,
            productId: entry.productId?.value,
            locationId: entry.locationId?.value,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleSubmitStockConfirmation = () => {
    setModalConfirmStock(false)
    setIsSubmitted(true)
    let isValid = productStockOut.warehouseId && productStockOut.date && productStockOut.name
    let hasNull = productStockOut.productStockOutProducts.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && productStockOut.productStockOutProducts.length > 0) {
      let data = {
        ...productStockOut,
        status: 1,
        date: productStockOut.date?.toISOString(),
        warehouseId: productStockOut.warehouseId?.value,
        fromId: productStockOut.fromId?.value,
        toId: productStockOut.toId?.value,
        productStockOutProducts: productStockOut.productStockOutProducts.map(entry => {
          return {
            ...entry,
            productId: entry.productId?.value,
            locationId: entry.locationId?.value,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleValidateStockOut = () => {
    let productStockOutProducts= productStockOut.productStockOutProducts.map(entry => {
      return {
        ...entry,
        productId: entry.productId?.value,
        locationId: entry.locationId?.value,
        unitOfMeasurementId: entry.unitOfMeasurementId?.value
      }
    })
    
    validateBulkProductStockOutRequest(productStockOutProducts).then(res => {
      if (res) {
        const { hasInvalid, results } = res

        if (hasInvalid) {
          setModalValidateResult(hasInvalid)
          setResultValidationData(results)
        } else {
          setModalConfirmStock(true)
        }
      }
    })
  }
  
  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col className={ "text-end" }>
                {
                  productStockOut.id !== NIL ?
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary" outline>
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                              onClick={ () => {
                                setModalPrintPreview(true)
                                setIsPrintForWarehouse(true);
                              }}
                              className="text-primary"
                          >
                            <i className="mdi mdi-printer me-1"/> Print For Warehouse
                          </DropdownItem>
                          <DropdownItem
                              onClick={ () => {setModalPrintPreview(true)
                                setIsPrintForWarehouse(false);  
                              }}
                              className="text-primary"
                          >
                            <i className="mdi mdi-printer me-1"/> Print For Customer
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown> : null
                }
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Code</Label>
                  <Input
                      type="text"
                      name="code"
                      placeholder="Auto Generate"
                      value={ productStockOut.code }
                      readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Label>From</Label>
                  <Select
                      name="fromId"
                      value={ productStockOut.fromId }
                      onChange={ handleSelectChange }
                      options={ transferLocations }
                      classNamePrefix="select2-selection"
                      placeholder="From"
                      isClearable
                  />
                </FormGroup>
                <FormGroup>
                  <Label>To</Label>
                  <Select
                      name="toId"
                      value={ productStockOut.toId }
                      onChange={ handleSelectChange }
                      options={ transferLocations }
                      classNamePrefix="select2-selection"
                      placeholder="To"
                      isClearable
                  />
                </FormGroup>
              </Col>
              <Col>
                <div className="mb-3">
                  <div
                      className={
                          "select2-container" +
                          (isSubmitted && !productStockOut.warehouseId ? " is-invalid" : "")
                      }
                  >
                  <Label htmlFor="">Warehouse</Label>
                  <WarehouseSelect value={ productStockOut.warehouseId } onChange={ option => {
                    setProductStockOut({
                      ...productStockOut,
                      warehouseId: option
                    })
                  } }/>
                  </div>
                  { isSubmitted && !productStockOut.warehouseId && (
                      <div className="invalid-feedback-custom">
                        Warehouse is required.
                      </div>
                  ) }
                </div>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Title</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Title"
                      autoComplete="off"
                      className={ isSubmitted && !productStockOut.name ? "is-invalid" : "" }
                      value={ productStockOut.name }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !productStockOut.name && (
                      <div className="invalid-feedback-custom">Name is required.</div>
                  ) }
                </FormGroup>
               
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Serial No</Label>
                  <Input
                      type="text"
                      name="serialNumber"
                      placeholder="Serial No"
                      autoComplete="off"
                      className={ isSubmitted && !productStockOut.serialNumber ? "is-invalid text-end" : "text-end" }
                      value={ productStockOut.serialNumber }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !productStockOut.serialNumber && (
                      <div className="invalid-feedback-custom">Serial No is required.</div>
                  ) }
                </FormGroup>
                <FormGroup
                    className={
                        "select2-container" + (isSubmitted && !productStockOut.date ? " is-invalid" : "")
                    }
                >
                  <Label>Stock In Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsStart
                      name="date"
                      selected={ productStockOut.date }
                      onChange={ obj => handleOnDateChange('date', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Stock In Date"
                      isClearable
                  />
                </FormGroup>
                { isSubmitted && !productStockOut.date && (
                    <div className="invalid-feedback-custom">
                      Stock In Date is required.
                    </div>
                ) }
              </Col>
            </Row>
            <Row>
              <Col md={ 4 }>
                
                
              </Col>
              <Col>

              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
              <Col>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <ProductSelect
                    name="productId"
                    warehouseId={productStockOut.warehouseId?.value}
                    placeholder={"Find by Code, Name, ..." }
                    onSelect={handleOnSelectProduct}/>
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button color={ "danger" }
                          onClick={ handleDeleteSelected }
                          outline
                          disabled={ !productStockOut.productStockOutProducts.some(e => e.isSelected) }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Location</th>
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>
              {
                productStockOut.productStockOutProducts
                    .sort((a, b) => (a.index < b.index ? 1 : -1))
                    .map((entry, index) => {
                  return <ProductStockOutRow
                      key={ index }
                      item={ entry }
                      isSubmitted={ isSubmitted }
                      onChange={ handleStockInItemChange }
                      onDelete={ handleDeleteStockInItem }
                  />
                })
              }
              </tbody>
              <tfoot>
              <tr>
                <th colSpan={2} className={'text-end'}>Total</th>
                <th className={'text-end'}>
                  {sumBy(productStockOut.productStockOutProducts, a =>{
                    return Number(a.quantity)
                  })}
                </th>
                <th></th>
                <th colSpan={2}></th>
              </tr>
              </tfoot>
            </Table>
          </CardBody>
        </Card>

        <Card className={ "mb-2" }>
          <CardBody>
            {
                [ 0 ].includes(productStockOut.status) && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleSubmit }
                        type="submit">
                  Submit
                </Button>
            }
            {
                productStockOut.id !== NIL && [ 0 ].includes(productStockOut.status) && productStockOut.productStockOutProducts.length > 0 && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleValidateStockOut }
                        type="submit">
                  Confirm Stock Out
                </Button>
            }

            <Button color="secondary"
                    onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>

        <ProductStockOutConfirmModal
            title="Confirm item into stock"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />

        {
          resultValidationData.length > 0 ?
              <ProductStockOutValidateResultModal
                  title={ "Validate Product Stock Out Result" }
                  isOpen={ modalValidateResult }
                  toggle={ () => setModalValidateResult(!modalValidateResult) }
                  items={ resultValidationData }
              /> : null
        }

        <PrintPreviewModal
            isOpen={ modalPrintPreview }
            isPrintForWarehouse={ isPrintForWarehouse }
            productStockOutId={ productStockOut.id }
            toggle={ () => setModalPrintPreview(!modalPrintPreview) }
        />
      </>
  )
}

ProductStockOutForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
}

export default ProductStockOutForm