import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import { warehouseService } from "../../services";

const WarehouseSelect = ({name, value, placeholder, isMulti, onChange}) => {
  const [ options, setOptions ] = useState([])
  
  useEffect(() => {
    warehouseService.getPagingRequest({ page: 1, size: 100 }).then(res => {
      const { data } = res;
      setOptions(data.map(item => ({
        key: item.id,
        value: item.id,
        label: item.name,
      })));
    })
  }, []);
  
  return (
      <div>
        <Select
            name={name}
            value={value}
            onChange={onChange}
            options={ options }
            classNamePrefix="select2-selection"
            placeholder={placeholder}
            isMulti={isMulti}
            isClearable
        />
      </div>
  );
};

WarehouseSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
};

export default WarehouseSelect;