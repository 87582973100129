import { call, put, takeEvery } from "redux-saga/effects";
import FileSaver from "file-saver";
import {
  ADD_NEW_PRODUCT_STOCK_OUT,
  GET_PRODUCT_STOCK_OUT_BY_ID,
  UPDATE_PRODUCT_STOCK_OUT,
  DELETE_PRODUCT_STOCK_OUT,
  GET_PRODUCT_STOCK_OUTS,
  GET_CONFIRM_PRODUCT_STOCK_OUT,
} from "./actionTypes";
import { get, post, del, put as update, getFile } from "../../helpers/api_helper"
import {
  getConfirmProductStockOutSuccess,
  getProductStockOutByIdSuccess,
  getProductStockOutsError,
  getProductStockOutsSuccess,
} from "./actions";

const getProductStockOutsRequest = query =>
  post("/api/ProductStockOut/paging", query);
const addNewProductStockOutRequest = item => post("/api/ProductStockOut", item);
const getProductStockOutByIdRequest = id => get(`/api/ProductStockOut/${id}`);
const getConfirmProductStockOutReqeust = id =>
  get(`/api/ProductStockOut/${id}/confirm`);

const validateProductStockOutRequest = id =>
    get(`/api/ProductStockOut/${id}/validate`);

const validateBulkProductStockOutRequest = data =>
    post(`/api/ProductStockOut/validate`, data);
const updateProductStockOutRequest = item =>
  update("/api/ProductStockOut", item);
const deleteProductStockOutRequest = id => del(`/api/ProductStockOut/${id}`);

const getProductStockOutProductToExcel = async (id, fileName) => {
  return await getFile(`/api/ProductStockOut/${id}/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* getProductStockOuts({ payload }) {
  try {
    const response = yield call(getProductStockOutsRequest, payload);
    yield put(getProductStockOutsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutsError(error));
  }
}

function* addNewProductStockOut({ payload }) {
  try {
    yield call(addNewProductStockOutRequest, payload);
    const response = yield call(getProductStockOutsRequest, {});
    yield put(getProductStockOutsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutsError(error));
  }
}

function* updateProductStockOut({ payload }) {
  try {
    yield call(updateProductStockOutRequest, payload);
    const response = yield call(getProductStockOutsRequest, {});
    yield put(getProductStockOutsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutsError(error));
  }
}

function* getProductStockOutById({ payload }) {
  try {
    var response = yield call(getProductStockOutByIdRequest, payload);
    yield put(getProductStockOutByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutsError(error));
  }
}

function* getConfirmProductStockOut({ payload }) {
  try {
    const { productStockOutId, type } = payload;

    let response = yield call(
      getConfirmProductStockOutReqeust,
      productStockOutId
    );
    if (type === "List") {
      response = yield call(getProductStockOutsRequest, {});
      yield put(getProductStockOutsSuccess(response));
    }
    console.log(response);
    yield put(getConfirmProductStockOutSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutsError(error));
  }
}

function* deleteProductStockOut({ payload }) {
  try {
    yield call(deleteProductStockOutRequest, payload);
    const response = yield call(getProductStockOutsRequest, {});
    yield put(getProductStockOutsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getProductStockOutsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_PRODUCT_STOCK_OUTS, getProductStockOuts);
  yield takeEvery(ADD_NEW_PRODUCT_STOCK_OUT, addNewProductStockOut);
  yield takeEvery(GET_PRODUCT_STOCK_OUT_BY_ID, getProductStockOutById);
  yield takeEvery(UPDATE_PRODUCT_STOCK_OUT, updateProductStockOut);
  yield takeEvery(DELETE_PRODUCT_STOCK_OUT, deleteProductStockOut);
  yield takeEvery(GET_CONFIRM_PRODUCT_STOCK_OUT, getConfirmProductStockOut);
}

export default itemSaga;

export {
  validateProductStockOutRequest,
  getProductStockOutProductToExcel,
  getProductStockOutByIdRequest,
  validateBulkProductStockOutRequest,
}
