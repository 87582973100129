import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row, Table } from "reactstrap"
import { NIL } from "uuid"
import DatePicker from "react-datepicker"
import { getMaxValue } from "../../helpers/utils"
import StockAdjustmentItemRow from "./StockAdjustmentItemRow"
import moment from "moment/moment"
import StockAdjustmentConfirmModal from "./StockAdjustmentConfirmModal"
import WarehouseSelect from "../warehouse/WarehouseSelect";
import ItemSelect from "../item/ItemSelect";

const StockAdjustmentForm = props => {
  const { item, onSubmit, onCancel } = props
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)

  const [ stockAdjustment, setStockAdjustment ] = useState({
    id: NIL,
    code: '',
    name: '',
    status: 0,
    warehouseId: null,
    date: new Date(),
    stockAdjustmentItems: []
  })

  const [ isSubmitted, setIsSubmitted ] = useState(false)
  

  useEffect(() => {
    if (item) {
      setStockAdjustment({
        ...item,
        warehouseId: item.warehouseId ? {
          id: item.warehouseId,
          value: item.warehouseId,
          label: item.warehouseName
        } : null,
        date: moment(item.date).toDate(),
        stockAdjustmentItems: item.stockAdjustmentItems && item.stockAdjustmentItems.map((a, index) => {
          return {
            ...a,
            index,
            label: `${ a.itemCode } - ${ a.itemName }`,
            itemId: {
              key: a.itemId,
              value: a.itemId,
            },
            unitOfMeasurementId: {
              id: a.itemUnitOfMeasurementId,
              value: a.unitOfMeasurementId,
              label: `${a.itemUnitOfMeasurementTitle} - ${a.locationName}`
            }
          }
        })
      })

    } else {
      setStockAdjustment({
        id: NIL,
        code: '',
        name: '',
        warehouseId: null,
        date: new Date(),
        status: 0,
        stockAdjustmentItems: []
      })
    }
  }, [ item ])
  

  const handleDeleteSelected = () => {
    setStockAdjustment({ ...stockAdjustment, stockAdjustmentItems: stockAdjustment.stockAdjustmentItems.filter(e => !e.isSelected) })
    setIsHeaderSelected(false)
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setStockAdjustment({
      ...stockAdjustment,
      stockAdjustmentItems: stockAdjustment.stockAdjustmentItems.map(entry => {
        return {
          ...entry,
          isSelected: checked
        }
      })
    })
  }

  const handleStockInItemChange = entry => {
    setStockAdjustment({
      ...stockAdjustment,
      stockAdjustmentItems: stockAdjustment.stockAdjustmentItems.map(a => a.index === entry.index ? entry : a)
    })
  }

  const handleDeleteStockInItem = (entry) => {
    setStockAdjustment({
      ...stockAdjustment,
      stockAdjustmentItems: stockAdjustment.stockAdjustmentItems.filter(e => e.index !== entry.index)
    })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setStockAdjustment({
      ...stockAdjustment,
      [name]: value
    })
  }

  const handleOnDateChange = (name, value) => {
    setStockAdjustment({
      ...stockAdjustment,
      [name]: value
    })
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    let isValid = stockAdjustment.date && stockAdjustment.name && stockAdjustment.warehouseId
    let hasNull = stockAdjustment.stockAdjustmentItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && stockAdjustment.stockAdjustmentItems.length > 0) {
      let data = {
        ...stockAdjustment,
        warehouseId: stockAdjustment.warehouseId?.value,
        date: stockAdjustment.date?.toISOString(),
        stockAdjustmentItems: stockAdjustment.stockAdjustmentItems.map(entry => {
          return {
            ...entry,
            itemId: entry.itemId?.value,
            itemUnitOfMeasurementId: entry.unitOfMeasurementId?.id,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value,
            locationId: entry.unitOfMeasurementId?.locationId,
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleSubmitStockConfirmation = () => {
    setModalConfirmStock(false)
    setIsSubmitted(true)
    let isValid = stockAdjustment.date && stockAdjustment.name && stockAdjustment.warehouseId
    let hasNull = stockAdjustment.stockAdjustmentItems.some(e => !e.unitOfMeasurementId || e.quantity === 0)


    if (!hasNull && isValid && stockAdjustment.stockAdjustmentItems.length > 0) {
      let data = {
        ...stockAdjustment,
        status: 1,
        warehouseId: stockAdjustment.warehouseId?.value,
        date: stockAdjustment.date?.toISOString(),
        stockAdjustmentItems: stockAdjustment.stockAdjustmentItems.map(entry => {
          return {
            ...entry,
            itemId: entry.itemId?.value,
            itemUnitOfMeasurementId: entry.unitOfMeasurementId?.id,
            unitOfMeasurementId: entry.unitOfMeasurementId?.value,
            locationId: entry.unitOfMeasurementId?.locationId,
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleSelectItem = (product) => {
    let max = 0
    if (stockAdjustment.stockAdjustmentItems.length > 0) {
      max = getMaxValue(
          stockAdjustment.stockAdjustmentItems.map(a => {
            return a.index
          })
      )
      max += 1
    } else {
      max = max + 1
    }

    let newEntry = {
      ...product,
      index: max,
    }

    setStockAdjustment({ ...stockAdjustment, stockAdjustmentItems: [ ...stockAdjustment.stockAdjustmentItems, newEntry ] })
  }
  
  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <CardTitle>Info</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <div className="mb-3">
                  <div
                      className={
                          "select2-container" +
                          (isSubmitted && !stockAdjustment.warehouseId ? " is-invalid" : "")
                      }
                  >

                    <Label htmlFor="">Warehouse</Label>
                    <WarehouseSelect value={ stockAdjustment.warehouseId } onChange={ option => {
                      setStockAdjustment({
                        ...stockAdjustment,
                        warehouseId: option
                      })
                    } }/>
                  </div>
                  { isSubmitted && !stockAdjustment.warehouseId && (
                      <div className="invalid-feedback-custom">
                        Warehouse is required.
                      </div>
                  ) }
                </div>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Code</Label>
                  <Input
                      type="text"
                      name="code"
                      placeholder="Auto Generate"
                      value={ stockAdjustment.code }
                      readOnly
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Title</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Title"
                      autoComplete="off"
                      className={ isSubmitted && !stockAdjustment.name ? "is-invalid" : "" }
                      value={ stockAdjustment.name }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !stockAdjustment.name && (
                      <div className="invalid-feedback-custom">Name is required.</div>
                  ) }
                </FormGroup>
              </Col>
              <Col>
                <FormGroup
                    className={
                        "select2-container" + (isSubmitted && !stockAdjustment.date ? " is-invalid" : "")
                    }
                >
                  <Label>Stock Adjustment Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsStart
                      name="date"
                      selected={ stockAdjustment.date }
                      onChange={ obj => handleOnDateChange('date', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Stock Adjustment Date"
                      isClearable
                  />
                </FormGroup>
                { isSubmitted && !stockAdjustment.date && (
                    <div className="invalid-feedback-custom">
                      Stock Adjustment Date is required.
                    </div>
                ) }
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
              <Col>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <ItemSelect
                    name="itemId"
                    warehouseId={ stockAdjustment.warehouseId?.value }
                    placeholder="Find by Code, Name, ..."
                    onSelect={ handleSelectItem }
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button color={ "danger" }
                          onClick={ handleDeleteSelected }
                          outline
                          disabled={ !stockAdjustment.stockAdjustmentItems.some(e => e.isSelected) }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>

              {
                stockAdjustment.stockAdjustmentItems.map((entry, index) => {
                  return <StockAdjustmentItemRow
                      key={ index }
                      item={ entry }
                      isSubmitted={ isSubmitted }
                      onChange={ handleStockInItemChange }
                      onDelete={ handleDeleteStockInItem }
                  />
                })
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card className={ "mb-2" }>
          <CardBody>
            {
                [ 0 ].includes(stockAdjustment.status) && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleSubmit }
                        type="submit">
                  Submit
                </Button>
            }
            {
                stockAdjustment.id !== NIL && [ 0 ].includes(stockAdjustment.status) && stockAdjustment.stockAdjustmentItems.length > 0 && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ () => setModalConfirmStock(true) }
                        type="submit">
                  Confirm Adjust Stock
                </Button>
            }

            <Button color="secondary"
                    onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>

        <StockAdjustmentConfirmModal
            title="Confirm item into stock"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />
      </>
  )
}

StockAdjustmentForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
}

export default StockAdjustmentForm