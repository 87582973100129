import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL as NIL_UUID } from "uuid";
import { Button, Col, FormGroup, Modal, Label, Row } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL_UUID,
      name: "",
      companyId: null,
    };
  }

  onOpened = () => {
    const { item } = this.props;
    if (item) {
      this.setState({ ...item });
    }
  };

  render() {
    const { isOpen, toggle, title, onSubmit } = this.props;

    return (
      <Modal
        size="sm"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            id: this.state.id,
            name: (this.state && this.state.name) || "",
            companyId: (this.state && this.state.companyId) || "",
            location: (this.state && this.state.location) || "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Please Enter Role Name"),
          })}
          onSubmit={values => {
            onSubmit(values);
          }}
        >
          {({ errors, status, touched }) => (
            <Form className="needs-validation">
              <div className="modal-body">
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="validationCustom01">Name</Label>
                      <Field
                        name="name"
                        placeholder="Name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="modal-footer">
                <Button color="primary" type="submit">
                  Submit
                </Button>
                <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
