import CustomPagination from "components/Common/CustomPagination"
import React, { useState } from "react"
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, Input,
  Table,
  UncontrolledDropdown,
} from "reactstrap"
import { hasAccess } from "../../helpers/utils"
import { permission } from "../../constants/permission"
import { sumBy } from "lodash"


const ItemTable = ({
                     itemCount,
                     page,
                     totalPage,
                     defaultPageSize,
                     items,
                     loading,
                     onDetails,
                     onEdit,
                     onConfirmDelete,
                     onChangePage,
                     onPageSizeChange,
                     onSort,
                     toggleSort
                   }) => {

  const displayLocations = items => {
    return items.map((a, index) => {
      return (
          <Badge key={ index } color="primary" className="px-2 py-1 mx-1">
            { a.name }
          </Badge>
      )
    })
  }

  const displayWarehouse = items => {
    return items.map((a, index) => {
      return (
          <Badge key={index} color="primary" className="px-2 py-1 mx-1">
            {a.name}
          </Badge>
      );
    });
  };

  const displayCategories = items => {
    return items.map((a, index) => {
      return (
          <Badge key={ index } color="primary" className="px-2 py-1 mx-1">
            { a.name }
          </Badge>
      )
    })
  }


  const renderColumn = (name, label, width) => {
    return <th style={ { width: width ?? "150px" } } className="text-center clickable"
               onClick={ () => onSort({ sortField: name, asc: toggleSort }) }>
      <span>{ label }</span>
      <span className={ 'ms-2' }> <i
          className={ "fas " + (toggleSort ? 'fa-caret-up' : 'fa-caret-down') }/></span>
    </th>
  }

  return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            { loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
            ) : (
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                  <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered table-nowrap"
                  >
                    <thead>
                    <tr>
                      <th style={ { width: "80px" } }>#</th>
                      <th className="text-center" style={ { width: "120px" } }>
                        Action
                      </th>
                      <th style={ { width: "100px" } }></th>
                      { renderColumn("Code", "Code", "120px") }
                      <th className="text-center" style={ { width: "300px" } }>Item Description</th>
                      <th className="text-center">Type</th>
                      <th className="text-center">Warehouses</th>
                      <th className="text-center">Locations</th>
                      <th className="text-center">Category</th>
                      { renderColumn("OnHand", "On Hand", "120px") }
                      { renderColumn("Outgoing", "Out going", "120px") }
                    </tr>
                    </thead>
                    <tbody>
                    { items.map((item, index) => {
                      const { itemAttachments } = item
                      let itemAttachment = itemAttachments.length > 0 ? itemAttachments[0] : null
                      let num = (page - 1) * defaultPageSize + (index + 1)

                      const { locations, warehouses, categories, itemUnitOfMeasurements } = item
                      let itemUomGroups = Object.groupBy(itemUnitOfMeasurements.filter(e => !e.isBase), a => {
                        return a.unitOfMeasurementName
                      })

                      let baseItemUom = itemUnitOfMeasurements.find(e => e.isBase)
                      let totalBase = sumBy(itemUnitOfMeasurements, a => {
                        return a.onHandQuantity * a.conversionRate
                      });

                      let totalOutgoing = sumBy(itemUnitOfMeasurements, a => {
                        return a.outgoingQuantity * a.conversionRate
                      });


                      return (
                          <tr key={ index } onDoubleClick={ () => onDetails(item.id) } style={ { cursor: "pointer" } }>
                            <td style={ { textAlign: "right" } }>{ num }</td>
                            <td>
                              <div className="text-center">
                                <UncontrolledDropdown>
                                  <DropdownToggle className="card-drop" tag="a">
                                    <i className="mdi mdi-dots-horizontal font-size-18"/>
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-start">
                                    { hasAccess(permission.item.read) &&
                                        <DropdownItem
                                            onClick={ () => onDetails(item.id) }
                                        >
                                          <i className="mdi mdi-clipboard-list-outline font-size-16 text-primary me-1"/>{ " " }
                                          Detail
                                        </DropdownItem>
                                    }

                                    { hasAccess(permission.item.read) &&
                                        <DropdownItem onClick={ () => onEdit(item.id) }>
                                          <i className="mdi mdi-pencil font-size-16 text-primary me-1"/>{ " " }
                                          Edit
                                        </DropdownItem>
                                    }
                                    { hasAccess(permission.item.delete) &&
                                        <DropdownItem
                                            onClick={ () => onConfirmDelete(item.id) }
                                        >
                                          <i className="mdi mdi-trash-can font-size-16 text-danger me-1"/>{ " " }
                                          Delete
                                        </DropdownItem>
                                    }
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                {
                                  itemAttachment ?
                                      <img style={ { width: "80px", height: "50px", objectFit: "contain" } }
                                           src={ itemAttachment.url } alt=""/> : <>
                                        <div className="avatar-order">
                                          <i className="fas fa-socks text-primary"></i>
                                        </div>
                                      </>
                                }
                                {/**/ }

                              </div>
                            </td>
                            <td className="text-end">{ item.code }</td>
                            <td>{ item.name }</td>
                            <td>{ item.type }</td>
                            <td>{ displayWarehouse(warehouses) }</td>
                            <td>{ displayLocations(locations) }</td>
                            <td>{ displayCategories(categories) }</td>
                            <td>
                              <ul className="mb-0">
                                { baseItemUom &&
                                    <li>
                                      <div className={ "d-flex justify-content-between" }>
                                        <span className={ "me-3" }>Total { baseItemUom?.unitOfMeasurementName } :</span>
                                        <NumberFormat
                                            className={ "fw-bolder" }
                                            value={ totalBase.toFixed(2) }
                                            displayType="text"
                                            thousandSeparator={ true }
                                            fixedDecimalScale={ false }
                                        />
                                      </div>
                                    </li>
                                }
                                {
                                  Object.entries(itemUomGroups).map(([ key, value ]) => {
                                    let total = sumBy(value, a => {
                                      return a.onHandQuantity
                                    })

                                    return <li key={ key }>
                                      <div className={ "d-flex justify-content-between" }>
                                        <span className={ "me-3" }>Total { key } :</span>
                                        <NumberFormat
                                            className={ "fw-bolder" }
                                            value={ total }
                                            displayType="text"
                                            thousandSeparator={ true }
                                            fixedDecimalScale={ false }
                                        />
                                      </div>
                                    </li>
                                  })
                                }
                              </ul>
                            </td>
                            <td>
                              <ul className="mb-0">
                                { baseItemUom &&
                                    <li>
                                      <div className={ "d-flex justify-content-between" }>
                                        <span className={ "me-3" }>Total { baseItemUom?.unitOfMeasurementName } :</span>
                                        <NumberFormat
                                            className={ "fw-bolder" }
                                            value={ totalOutgoing.toFixed(2) }
                                            displayType="text"
                                            thousandSeparator={ true }
                                            fixedDecimalScale={ false }
                                        />
                                      </div>
                                    </li>
                                }
                                {
                                  Object.entries(itemUomGroups).map(([ key, value ]) => {
                                    let total = sumBy(value, a => {
                                      return a.outgoingQuantity
                                    })

                                    return <li key={ key }>
                                      <div className={ "d-flex justify-content-between" }>
                                        <span className={ "me-3" }>Total { key } :</span>
                                        <NumberFormat
                                            className={ "fw-bolder" }
                                            value={ total }
                                            displayType="text"
                                            thousandSeparator={ true }
                                            fixedDecimalScale={ false }
                                        />
                                      </div>
                                    </li>
                                  })
                                }
                              </ul>
                            </td>
                          </tr>
                      )
                    }) }
                    </tbody>
                  </Table>
                </div>
            ) }
          </div>
          <CustomPagination
              itemCount={ itemCount }
              currentPage={ page }
              totalPage={ totalPage }
              defaultPageSize={ defaultPageSize }
              pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
              onChangePage={ i => onChangePage(i) }
              onPageSizeChange={ size => onPageSizeChange(size) }
          />
        </CardBody>
      </Card>
  )
}

ItemTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onDetails: PropTypes.func,
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSort: PropTypes.func,
  toggleSort: PropTypes.func,
}

export default ItemTable
