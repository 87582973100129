import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENT_BY_ID,
  UPDATE_STOCK_ADJUSTMENT,
  DELETE_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENTS,
  GET_CONFIRM_STOCK_ADJUSTMENT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getConfirmStockAdjustmentSuccess,
  getStockAdjustmentByIdSuccess,
  getStockAdjustmentsError,
  getStockAdjustmentsSuccess,
} from "./actions";

const getStockAdjustmentsRequest = query =>
  post("/api/StockAdjustment/paging", query);
const addNewStockAdjustmentRequest = item => post("/api/StockAdjustment", item);
const getStockAdjustmentByIdRequest = id => get(`/api/StockAdjustment/${id}`);
const getConfirmStockAdjustmentReqeust = id =>
  get(`/api/StockAdjustment/${id}/confirm`);
const updateStockAdjustmentRequest = item =>
  update("/api/StockAdjustment", item);
const deleteStockAdjustmentRequest = id => del(`/api/StockAdjustment/${id}`);

function* getStockAdjustments({ payload }) {
  try {
    const response = yield call(getStockAdjustmentsRequest, payload);
    yield put(getStockAdjustmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentsError(error));
  }
}

function* addNewStockAdjustment({ payload }) {
  try {
    yield call(addNewStockAdjustmentRequest, payload);
    const response = yield call(getStockAdjustmentsRequest, {});
    yield put(getStockAdjustmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentsError(error));
  }
}

function* updateStockAdjustment({ payload }) {
  try {
    yield call(updateStockAdjustmentRequest, payload);
    const response = yield call(getStockAdjustmentsRequest, {});
    yield put(getStockAdjustmentsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentsError(error));
  }
}

function* getStockAdjustmentById({ payload }) {
  try {
    let response = yield call(getStockAdjustmentByIdRequest, payload);
    yield put(getStockAdjustmentByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentsError(error));
  }
}

function* getConfirmStockAdjustment({ payload }) {
  try {
    const { stockAdjustmentId, type } = payload;

    let response = yield call(
      getConfirmStockAdjustmentReqeust,
      stockAdjustmentId
    );
    if (type === "List") {
      response = yield call(getStockAdjustmentsRequest, {});
      yield put(getStockAdjustmentsSuccess(response));
    }
    yield put(getConfirmStockAdjustmentSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentsError(error));
  }
}

function* deleteStockAdjustment({ payload }) {
  try {
    yield call(deleteStockAdjustmentRequest, payload);
    const response = yield call(getStockAdjustmentsRequest, {});
    yield put(getStockAdjustmentsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockAdjustmentsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_ADJUSTMENTS, getStockAdjustments);
  yield takeEvery(ADD_NEW_STOCK_ADJUSTMENT, addNewStockAdjustment);
  yield takeEvery(GET_STOCK_ADJUSTMENT_BY_ID, getStockAdjustmentById);
  yield takeEvery(UPDATE_STOCK_ADJUSTMENT, updateStockAdjustment);
  yield takeEvery(DELETE_STOCK_ADJUSTMENT, deleteStockAdjustment);
  yield takeEvery(GET_CONFIRM_STOCK_ADJUSTMENT, getConfirmStockAdjustment);
}

export default itemSaga;
