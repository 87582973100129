import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container, FormGroup,
  Input,
  Row,
} from "reactstrap";
import {
  getProductStockIns,
  addNewProductStockIn,
  updateProductStockIn,
  getProductStockInById,
  deleteProductStockIn,
  clearProductStockInError,
  getConfirmProductStockIn,
} from "store/actions";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";

import { debounce } from "lodash";
import ModalForm from "containers/product-stock-in/ModalForm";
import ProductStockInConfirmModal from "containers/product-stock-in/ProductStockInConfirmModal";
import ProductStockInTable from "containers/product-stock-in/ProductStockInTable";
import WarehouseSelect from "../../containers/warehouse/WarehouseSelect";

const ProductStockInList = props => {
  const [ id, setId ] = useState(null);
  const [ term, setTerm ] = useState("");
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ warehouses, setWarehouses ] = useState([])
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false);

  const {
    getProductStockIns,
    addNewProductStockIn,
    deleteProductStockIn,
    updateProductStockIn,
    clearProductStockInError,
    getProductStockInById,
    getConfirmProductStockIn,
    message,
    items,
    item,
    loading,
    itemCount,
    pageCount,
    history,
  } = props;

  const debouncedFetchData = useCallback(
      debounce(term => {
        setTerm(term);
      }, 1000),
      []
  );

  useEffect(() => {
    getProductStockIns({ 
      page, 
      pageSize, 
      term, 
      warehouseIds: warehouses.map(warehouse => warehouse.value) 
    });
  }, [ page, pageSize, term, warehouses ]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearProductStockInError();
    }
  }, [ message ]);


  const handleUpdate = supplier => {
    updateProductStockIn(supplier);
    setModalEditIsOpen(false);
  };

  const handleOnAddNew = () => {
    history.push(`/product-stock-in/add-new`);
  };

  const handleSubmit = item => {
    addNewProductStockIn(item);
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteProductStockIn(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnDetail = id => {
    history.push(`/product-stock-in/${ id }`);
  };

  const handleConfirmStock = id => {
    setId(id);
    setModalConfirmStock(true);
  };

  const handleSubmitStockConfirmation = () => {
    getConfirmProductStockIn({ productStockInId: id, type: "List" });
    setModalConfirmStock(false);
  };

  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Product Stock In | CR TechSoft</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
                title="Product Stock In"
                breadcrumbItem="Product Stock In List"
            />
            <Row>
              <Col md={ 12 }>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={ 2 }>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                                type="text"
                                placeholder="Quick Search ..."
                                onChange={ e => {
                                  debouncedFetchData(e.target.value);
                                } }
                            ></Input>
                            <i className="fas fa-search search-icon"/>
                          </div>
                        </div>
                      </Col>
                      <Col md={ 4 }>
                        <FormGroup>
                          <WarehouseSelect
                              name="warehouses"
                              placeholder={ "Warehouse" }
                              value={ warehouses }
                              onChange={ option => {
                                setPage(1);
                                setWarehouses(option)
                              } }
                              isMulti={ true }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={ 2 }></Col>
                      <Col md={ 4 }>
                        <div className="text-sm-end">
                          <Button
                              type="button"
                              color="primary"
                              className="me-1"
                              onClick={ handleOnAddNew }
                          >
                            <i className="fas fa-plus"/> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Collapse isOpen={ false }>
                    <CardBody className="pb-1"></CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col md={ 12 }>
                { items && (
                    <ProductStockInTable
                        items={ items }
                        itemCount={ itemCount }
                        page={ page }
                        totalPage={ pageCount }
                        loading={ loading }
                        defaultPageSize={ pageSize }
                        onDetails={ handleOnDetail }
                        onConfirm={ handleConfirmStock }
                        onConfirmDelete={ handleConfirmDelete }
                        onChangePage={ page => setPage(page) }
                        onPageSizeChange={ pageSize => {
                          setPage(1);
                          setPageSize(pageSize);
                        } }
                    />
                ) }
                { item && (
                    <ModalForm
                        title={ "Edit Stock In" }
                        item={ item }
                        isOpen={ modalEditIsOpen }
                        toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                        onSubmit={ handleUpdate }
                    />
                ) }
                <ModalForm
                    title={ "Add Stock IN" }
                    isOpen={ modalAddIsOpen }
                    toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
                    onSubmit={ handleSubmit }
                />
                <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={ modalConfirmDeleteIsOpen }
                    toggle={ () =>
                        setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)
                    }
                    onSubmit={ handleDelete }
                />
                <ProductStockInConfirmModal
                    title="Confirm item into stock"
                    isOpen={ modalConfirmStock }
                    toggle={ () => setModalConfirmStock(!modalConfirmStock) }
                    onSubmit={ handleSubmitStockConfirmation }
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
  );
};

ProductStockInList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  history: PropTypes.object,

  getProductStockIns: PropTypes.func,
  addNewProductStockIn: PropTypes.func,
  updateProductStockIn: PropTypes.func,
  getProductStockInById: PropTypes.func,
  deleteProductStockIn: PropTypes.func,
  clearProductStockInError: PropTypes.func,
  getConfirmProductStockIn: PropTypes.func,
};

const mapStateToProps = ({ productStockIn }) => {
  return productStockIn;
};

export default withRouter(
    connect(mapStateToProps, {
      getProductStockIns,
      addNewProductStockIn,
      updateProductStockIn,
      getProductStockInById,
      deleteProductStockIn,
      clearProductStockInError,
      getConfirmProductStockIn,
    })(ProductStockInList)
);
