import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import { debounce } from "lodash";
import { getPagingLocationRequest } from "../../store/location/saga";

const LocationSelection = ({ name, value, onChange, isMulti, placeholder }) => {
  const [ term, setTerm ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ options, setOptions ] = useState([]);

  const debouncedSearch = useCallback(
      debounce(term => {
        setTerm(term);
      }, 500),
      []
  )

  useEffect(() => {
    setLoading(true);
    getPagingLocationRequest({ term: term, page: 1, size: 10 }).then(res => {
      const { data } = res;
      setOptions(data.map(item => ({
        key: item.id,
        value: item.id,
        label: item.name,
      })));
      setLoading(false);
    })
  }, [ term ]);


  return (
      <Select
          name={ name }
          value={ value }
          onChange={ onChange }
          options={ options }
          onInputChange={ value => debouncedSearch(value) }
          classNamePrefix="select2-selection"
          placeholder={placeholder}
          isLoading={ loading }
          isMulti={isMulti}
          isClearable
      />
  );
};

LocationSelection.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default LocationSelection;