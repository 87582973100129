import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getItemUnitOfMeasurements,
  addNewItemUnitOfMeasurement,
  getItemUnitOfMeasurementById,
  updateItemUnitOfMeasurement,
  deleteItemUnitOfMeasurement,
  clearItemUnitOfMeasurementError,
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import ModalForm from "containers/item-unit-of-measurement/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { toastMessage } from "helpers/utils";
import NumberFormat from "react-number-format"
import { debounce } from "lodash";

const ItemUnitOfMeasurementTab = props => {
  const [ selectedId, setSelectedId ] = useState(null);
  const [ term, setTerm ] = useState('')
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(50);

  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalEditIsOpen, setModalEditIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term);
        setPage(1);
      }, 500),
      []
  );

  const {
    getItemUnitOfMeasurements,
    addNewItemUnitOfMeasurement,
    getItemUnitOfMeasurementById,
    updateItemUnitOfMeasurement,
    deleteItemUnitOfMeasurement,
    clearItemUnitOfMeasurementError,
    items,
    item,
    totalAmount,
    message,
    itemCount,
    pageCount,
    loading,
    totalUom,
    itemId,
  } = props;

  useEffect(() => {
    getItemUnitOfMeasurements({
      term,
      itemId,
      page,
      pageSize
    });
  }, [ itemId, term, page, pageSize ]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearItemUnitOfMeasurementError();
    }
  }, [ message ]);

  const handleSubmit = data => {
    const dataSubmit = {
      ...data,
      itemId,
    };

    addNewItemUnitOfMeasurement(dataSubmit);
    setModalAddIsOpen(false);
  };

  const handleUpdate = data => {
    const dataSubmit = {
      ...data,
      itemId,
    };
    updateItemUnitOfMeasurement(dataSubmit);
    setModalEditIsOpen(false);
  };

  const handleDelete = () => {
    deleteItemUnitOfMeasurement({ itemId, id: selectedId, });
    setModalConfirmDeleteIsOpen(false);
  };

  return (
      <div>
        <Card className="mb-1">
          <CardHeader className="bg-transparent border-bottom">
            <Row className="g-1">
              <Col md={ 2 }>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={ e => {
                          const { value } = e.target;
                          debouncedQuickSearch(value);
                        } }
                    ></Input>
                    <i className="fas fa-search search-icon"/>
                  </div>
                </div>
              </Col>
              <Col md={ 10 }>
                <div className="text-sm-end">
                  <Button
                      type="button"
                      color="primary"
                      className="me-1"
                      onClick={ () => setModalAddIsOpen(true) }
                  >
                    <i className="fas fa-plus"/> Add New
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
        </Card>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              { loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border text-primary m-1" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
              ) : (
                  <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                  >
                    <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered table-nowrap"
                    >
                      <thead>
                      <tr>
                        <th style={ { width: "80px" } }>#</th>
                        <th>Title</th>
                        <th>Warehouse</th>
                        <th>Location</th>
                        <th>On Hand</th>
                        <th>Out going</th>
                        <th>Min</th>
                        <th>UoM</th>
                        <th>Conversion Rate</th>
                        <th>Amount (Based)</th>
                        <th>Display Order</th>
                        <th>Is Base</th>
                        <th>Note</th>
                        <th style={ { width: "120px" } }>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      { items.map((item, index) => {
                        let num = (page - 1) * pageSize + (index + 1);
                        return (
                            <tr key={ index }>
                              <td style={ { textAlign: "right" } }>{ num }</td>
                              <td>{ item.title }</td>
                              <td>{ item.warehouseName }</td>
                              <td>{ item.locationName }</td>


                              <td className="text-end">
                                <NumberFormat
                                    value={ item.onHandQuantity }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ item.outgoingQuantity }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ item.minQuantity }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td>{ item.unitOfMeasurementName }</td>
                              <td className="text-end">
                                { item.conversionRate }
                              </td>
                              <td className="text-end">
                                <NumberFormat
                                    value={ Number(item.onHandQuantity * item.conversionRate).toFixed(2) }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </td>
                              <td className="text-end">{ item.displayOrder }</td>
                              <td className="text-end">{ item.isBase ? "Yes" : "No" }</td>
                              <td>{ item.note }</td>
                              <td>
                                <div className="d-flex gap-3">
                                  <Link className="text-primary" to="#">
                                    <i
                                        className="mdi mdi-pencil font-size-18"
                                        id="edittooltip"
                                        onClick={ () => {
                                          getItemUnitOfMeasurementById(item.id);
                                          setModalEditIsOpen(true);
                                        } }
                                    ></i>
                                  </Link>

                                  <Link className="text-danger" to="#">
                                    <i
                                        className="mdi mdi-delete font-size-18"
                                        id="deletetooltip"
                                        onClick={ () => {
                                          setSelectedId(item.id);
                                          setModalConfirmDeleteIsOpen(true);
                                        } }
                                    ></i>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                        );
                      }) }
                      </tbody>
                      <tfoot>
                      {
                          totalUom && Object.entries(totalUom).map((a, index) => {
                            return <tr key={ index }>
                              <th colSpan={ 7 }>
                                <div className={ "text-end" }>Total { a[0] }</div>
                              </th>
                              <th className="text-end">
                                <NumberFormat
                                    value={ a[1] }
                                    displayType="text"
                                    thousandSeparator={ true }
                                    fixedDecimalScale={ false }
                                />
                              </th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                            </tr>
                          })
                      }

                      </tfoot>
                    </Table>
                  </div>
              ) }
            </div>
            <CustomPagination
                itemCount={ itemCount }
                currentPage={ page }
                totalPage={ pageCount }
                defaultPageSize={ pageSize }
                pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                onChangePage={ i => setPage(i) }
                onPageSizeChange={ size => {
                  setPage(1);
                  setPageSize(size);
                } }
            />
          </CardBody>
        </Card>
        <ModalForm
            title="Add Unit of Measurement"
            isOpen={ modalAddIsOpen }
            toggle={ () => setModalAddIsOpen(!modalAddIsOpen) }
            onSubmit={ handleSubmit }
        />
        { item ? (
            <ModalForm
                title="Edit Unit of Measurement"
                item={ item }
                isOpen={ modalEditIsOpen }
                toggle={ () => setModalEditIsOpen(!modalEditIsOpen) }
                onSubmit={ handleUpdate }
            />
        ) : null }

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />
      </div>
  );
};


ItemUnitOfMeasurementTab.propTypes = {
  itemId: PropTypes.string,
  message: PropTypes.object,
  invoiceId: PropTypes.string,
  totalUom: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  totalAmount: PropTypes.number,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getItemUnitOfMeasurements: PropTypes.func,
  addNewItemUnitOfMeasurement: PropTypes.func,
  getItemUnitOfMeasurementById: PropTypes.func,
  updateItemUnitOfMeasurement: PropTypes.func,
  deleteItemUnitOfMeasurement: PropTypes.func,
  clearItemUnitOfMeasurementError: PropTypes.func,
};

const mapStateToProps = ({ itemUnitOfMeasurement }) => {
  return itemUnitOfMeasurement;
};

export default withRouter(
    connect(mapStateToProps, {
      getItemUnitOfMeasurements,
      addNewItemUnitOfMeasurement,
      getItemUnitOfMeasurementById,
      updateItemUnitOfMeasurement,
      deleteItemUnitOfMeasurement,
      clearItemUnitOfMeasurementError,
    })(ItemUnitOfMeasurementTab)
);
