import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import RoleList from "../pages/UserManagement/Role/role-list";
import RoleDetail from "pages/UserManagement/Role/RoleDetail";
import UserList from "../pages/UserManagement/User/user-list";
import UserDetail from "../pages/UserManagement/User/UserDetail";

import UnitList from "../pages/Inventory/Unit/unit-list";
import OrderList from "../pages/Order/OrderList";
import AddNewOrder from "../pages/Order/AddNewOrder";
import OrderDetail from "../pages/Order/OrderDetail";

import WarehouseList from "pages/Inventory/Warehouse/warehouse-list";
import CategoryList from "pages/Inventory/Category/category-list";
import AttributeList from "pages/Inventory/Attribute/attribute-list";
import CurrencyList from "pages/Inventory/Currency/currency-list";
import BannerList from "pages/Inventory/Banner/banner-list";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Settings from "../pages/Settings/index";
import ShippingFee from "../pages/ShippingFee/index";
import ShippingFeeDetail from "../pages/ShippingFee/detail";
import PermissionList from "../pages/UserManagement/permission/permission-list";
import TransportList from "../pages/Transport/TransportList";
import TransportDetail from "../pages/Transport/TransportDetail";
import AddNewTransport from "../pages/Transport/AddNewTransport";
import InvoiceList from "pages/Invoice/InvoiceList";
import InvoiceDetail from "pages/Invoice/InvoiceDetail";
import ReceiveOrderList from "pages/ReceiveOrder/ReceiveOrderList";
import InvoiceNoteTab from "pages/Invoice/InvoiceNoteTab";
import PaymentHistoryList from "pages/InvoicePaymentHistory/PaymentHistoryList";
import ReceiveOrderDetail from "pages/ReceiveOrder/ReceiveOrderDetail";
import OrderVerifyTrackingList from "pages/OrderVerifyTracking/OrderVerifyTrackingList";
import DeliveryAgencyList from "pages/DeliveryAgency/DeliveryAgencyList";
import DelivererList from "pages/Deliverer/DelivererList";
import DeliveryOrderList from "pages/DeliveryOrder/DeliveryOrderList";
import AddNewDeliveryOrder from "pages/DeliveryOrder/AddNewDeliveryOrder";
import DeliveryOrderDetail from "pages/DeliveryOrder/DeliveryOrderDetail";
import DeviceInfoList from "pages/DeviceInfo/DeviceInfoList";
import DeviceInfoDetail from "pages/DeviceInfo/DeviceInfoDetail";
import FeedbackList from "pages/Feedback/FeedbackList";
import FeedbackDetail from "pages/Feedback/FeedbackDetail";
import SupplierList from "pages/Supplier/SupplierList";
import CompanyList from "pages/Company/CompanyList";
import StoreList from "pages/Store/StoreList";
import ProductList from "pages/Product/ProductList";
import ProductDetail from "pages/Product/ProductDetail";
import AddNewProduct from "pages/Product/AddNewProduct";
import ItemList from "pages/Item/ItemList";
import UnitOfMeasurementList from "pages/UnitOfMeasurement/UnitOfMeasurementList";
import ItemDetail from "pages/Item/ItemDetail";
import StockInList from "pages/StockIn/StockInList";
import StockInDetail from "pages/StockIn/StockInDetail";
import StockAdjustmentList from "pages/StockAdjustment/StockAdjustmentList";
import StockAdjustmentDetail from "pages/StockAdjustment/StockAdjustmentDetail";
import PurchaseOrderList from "pages/PurchaseOrder/PurchaseOrderList";
import PurchaseOrderDetail from "pages/PurchaseOrder/PurchaseOrderDetail";
import StockOutList from "pages/StockOut/StockOutList";
import StockOutDetail from "pages/StockOut/StockOutDetail";
import ProductStockInList from "pages/ProductStockIn/ProductStockInList";
import ProductStockInDetail from "pages/ProductStockIn/ProductStockInDetail";
import ProductStockOutList from "pages/ProductStockOut/ProductStockOutList";
import ProductStockOutDetail from "pages/ProductStockOut/ProductStockOutDetail";
import ClothingSizeList from "pages/ClothingSize/ClothingSizeList";
import CuttingList from "pages/Cutting/CuttingList";
import CuttingDetail from "pages/Cutting/CuttingDetail";
import SewingList from "pages/Sewing/SewingList";
import SewingDetail from "pages/Sewing/SewingDetail";
import PackagingList from "../pages/Packaging/PackagingList";
import PackagingDetail from "../pages/Packaging/PackagingDetail";
import StockRequestList from "../pages/StockRequest/StockRequestList";
import stockRequestDetail from "../pages/StockRequest/StockRequestDetail";
import FinishedGoodsList from "../pages/FinishedGoods/FinishedGoodsList"
import FinishedGoodsDetail from "../pages/FinishedGoods/FinishedGoodsDetail"
import ProductStockOutReport from "../pages/Reports/ProductStockOutReport"
import ItemStockInReport from "../pages/Reports/ItemStockInReport"
import ItemStockOutReport from "../pages/Reports/ItemStockOutReport"
import AddNewStockRequest from "../pages/StockRequest/AddNewStockRequest"
import AddNewStockIn from "../pages/StockIn/AddNewStockIn"
import AddNewStockOut from "../pages/StockOut/AddNewStockOut"
import AddNewProductStockIn from "../pages/ProductStockIn/AddNewProductStockIn"
import AddNewProductStockOut from "../pages/ProductStockOut/AddNewProductStockOut"
import AddNewStockAdjustment from "../pages/StockAdjustment/AddNewStockAdjustment"
import MaterialListReport from "../pages/Reports/MaterialListReport"
import PurchaseOrderSummaryReport from "../pages/Reports/PurchaseOrderSummaryReport"
import TransferLocationList from "../pages/TransferLocation/TransferLocationList"
import SewingOutstandingReport from "../pages/Reports/SewingOutstandingReport"
import AddNewPurchaseOrder from "../pages/PurchaseOrder/AddNewPurchaseOrder"
import CodeColorList from "../pages/CodeColor/CodeColorList"
import LocationList from "../pages/Location/LocationList"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  { path: "/order", component: OrderList },
  { path: "/order-verify-tracking", component: OrderVerifyTrackingList },
  { path: "/order/add-new", component: AddNewOrder },
  { path: "/order/:id", component: OrderDetail },

  { path: "/transport", component: TransportList },
  { path: "/transport/add-new", component: AddNewTransport },
  { path: "/transport/:id", component: TransportDetail },
  { path: "/receive-order", component: ReceiveOrderList },
  { path: "/receive-order/:id", component: ReceiveOrderDetail },

  { path: "/user", component: UserList },
  { path: "/user/:id", component: UserDetail },
  { path: "/user/:id/:activeTab", component: UserDetail },
  { path: "/role", component: RoleList },
  { path: "/role/:id", component: RoleDetail },
  { path: "/inventory/unit", component: UnitList },
  { path: "/warehouse", component: WarehouseList },
 
  { path: "/inventory/attribute", component: AttributeList },
  { path: "/setting", component: Settings },
  { path: "/shipping-fee", component: ShippingFee },
  { path: "/shipping-fee/:id", component: ShippingFeeDetail },
  { path: "/currency", component: CurrencyList },
  { path: "/inventory/banner", component: BannerList },
  { path: "/permission", component: PermissionList },

  { path: "/delivery-agency", component: DeliveryAgencyList },
  { path: "/deliverer", component: DelivererList },
  { path: "/delivery-order", component: DeliveryOrderList },
  { path: "/delivery-order/add-new", component: AddNewDeliveryOrder },
  { path: "/delivery-order/:id", component: DeliveryOrderDetail },

  { path: "/invoice", component: InvoiceList },
  { path: "/invoice/:id", component: InvoiceDetail },
  { path: "/invoice/:id/:userId", component: InvoiceDetail },
  { path: "/invoiceNote", component: InvoiceNoteTab },

  { path: "/invoice-payment-history", component: PaymentHistoryList },
  { path: "/feedback", component: FeedbackList },
  { path: "/feedback/:id", component: FeedbackDetail },

  { path: "/device-info", component: DeviceInfoList },
  { path: "/device-info/:id", component: DeviceInfoDetail },
  { path: "/device-info/:id/:userId", component: DeviceInfoDetail },

  { path: "/product", component: ProductList },
  { path: "/product/add-new", component: AddNewProduct },
  { path: "/product/:id", component: ProductDetail },

  { path: "/supplier", component: SupplierList },
  { path: "/company", component: CompanyList },
  { path: "/store", component: StoreList },

  { path: "/item", component: ItemList },
  { path: "/item/:id", component: ItemDetail },

  { path: "/unit-of-measurement", component: UnitOfMeasurementList },
  { path: "/transfer-location", component: TransferLocationList },
  { path: "/code-color", component: CodeColorList },
  { path: "/location", component: LocationList },
  { path: "/clothing-size", component: ClothingSizeList },
  { path: "/category", component: CategoryList },
    
  { path: "/stock-in", component: StockInList },
  { path: "/stock-in/add-new", component: AddNewStockIn },
  { path: "/stock-in/:id", component: StockInDetail },

  { path: "/stock-adjustment", component: StockAdjustmentList },
  { path: "/stock-adjustment/add-new", component: AddNewStockAdjustment },
  { path: "/stock-adjustment/:id", component: StockAdjustmentDetail },

  { path: "/purchase-order/add-new", component: AddNewPurchaseOrder },
  { path: "/purchase-order", component: PurchaseOrderList },
  { path: "/purchase-order/:id", component: PurchaseOrderDetail },

  { path: "/cutting", component: CuttingList },
  { path: "/cutting/:id", component: CuttingDetail },

  { path: "/sewing", component: SewingList },
  { path: "/sewing/:id", component: SewingDetail },

  { path: "/packaging", component: PackagingList },
  { path: "/packaging/:id", component: PackagingDetail },

  { path: "/finished-goods", component: FinishedGoodsList },
  { path: "/finished-goods/:id", component: FinishedGoodsDetail },

  { path: "/stock-request", component: StockRequestList },
  { path: "/stock-request/:stockRequestType/add-new", component: AddNewStockRequest },
  { path: "/stock-request/:stockRequestType", component: StockRequestList },
  { path: "/stock-request/:id", component: stockRequestDetail },
  { path: "/stock-request/:stockRequestType/:id", component: stockRequestDetail },

  { path: "/stock-out", component: StockOutList },
  { path: "/stock-out/add-new", component: AddNewStockOut },
  { path: "/stock-out/:id", component: StockOutDetail },

  { path: "/product-stock-in", component: ProductStockInList },
  { path: "/product-stock-in/add-new", component: AddNewProductStockIn },
  { path: "/product-stock-in/:id", component: ProductStockInDetail },

  { path: "/product-stock-out", component: ProductStockOutList },
  { path: "/product-stock-out/add-new", component: AddNewProductStockOut },
  { path: "/product-stock-out/:id", component: ProductStockOutDetail },
  

  // Reports
  { path: "/product-stock-out-report", component: ProductStockOutReport },
  { path: "/item-stock-in-report", component: ItemStockInReport },
  { path: "/item-stock-out-report", component: ItemStockOutReport },
  { path: "/purchase-order-summary-report", component: PurchaseOrderSummaryReport },
  { path: "/sewing-outstanding-report", component: SewingOutstandingReport },
    
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
