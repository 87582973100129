import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { customSingleValue, formatItemOptionLabel } from "../../helpers/utils";
import Select from "react-select";
import { debounce } from "lodash";
import { getItemUnitOfMeasurementsRequest } from "../../store/item-unit-of-measurement/saga";
import { NIL } from "uuid";
import { getItemsRequest } from "../../store/item/saga";

const ItemSelect = ({ name, warehouseId, placeholder, className, onSelect }) => {
  const [ term, setTerm ] = useState('')
  const [ itemOptions, setItemOptions ] = useState([])
  const [ loading, setLoading ] = useState(false)

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term)
      }, 500),
      []
  )

  const handleInputChange = (value) => {
    debouncedQuickSearch(value)
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    switch (name) {
      case 'itemId':
        if (valueType) {
          const { value, label } = valueType
          getItemUnitOfMeasurementsRequest({ 
            itemId: value, 
            pageSize: 100,
            warehouseIds: warehouseId ? [ warehouseId ] : []
          }).then(res => {
            const { data } = res
            let uomOptions = data?.map(a => {
              return {
                id: a.id,
                value: a.unitOfMeasurementId,
                locationId: a.locationId,
                label: `${ a.title } - ${ a.locationName }`
              }
            }) ?? []

            let newEntry = {
              id: NIL,
              itemId: valueType,
              label: label,
              quantity: 1,
              itemUnitOfMeasurementId: null,
              unitOfMeasurementId: uomOptions.length > 0 ? uomOptions[0] : null,
              uomOptions,
              isSelected: false
            }

            onSelect(newEntry)
          })
        }
        break
    }
  }

  useEffect(() => {
    setLoading(true)
    
    if (warehouseId) {
      getItemsRequest({ 
        term, 
        page: 1, 
        pageSize: 20,
        warehouseIds: warehouseId ? [ warehouseId ] : []
      }).then(res => {
        if (res.data) {
          setLoading(false)
          setItemOptions(res.data.map((a, index) => {
            return {
              key: a.id,
              value: a.id,
              type: a.type,
              itemAttachment: a.itemAttachments.length > 0 ? a.itemAttachments[0] : null,
              quantity: a.onHandQuantity,
              label: `${ a.code } - ${ a.name }`
            }
          }))
        }
      })
    }
  }, [ term, warehouseId ])


  return (
      <Select
          name={ name }
          value={ null }
          placeholder={ placeholder }
          className={ className }
          onChange={ handleSelectChange }
          options={ itemOptions }
          components={ {
            SingleValue: customSingleValue
          } }
          onInputChange={ handleInputChange }
          formatOptionLabel={ formatItemOptionLabel }
          classNamePrefix="select2-selection"
          isLoading={ loading }
          isClearable
      />
  );
};

ItemSelect.propTypes = {
  name: PropTypes.string,
  warehouseId: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onSelect: PropTypes.func,
};

export default ItemSelect;