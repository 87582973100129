import { call, put, takeEvery } from "redux-saga/effects";
import FileSaver from "file-saver";
import {
  ADD_NEW_STOCK_IN,
  GET_STOCK_IN_BY_ID,
  UPDATE_STOCK_IN,
  DELETE_STOCK_IN,
  GET_STOCK_INS,
  GET_CONFIRM_STOCK_IN,
} from "./actionTypes";
import {
  get,
  post,
  del,
  put as update,
  getFile,
} from "../../helpers/api_helper";
import {
  getConfirmStockInSuccess,
  getStockInByIdSuccess,
  getStockInsError,
  getStockInsSuccess,
} from "./actions";

const getStockInsRequest = query =>
    post("/api/StockIn/paging", query);
const addNewStockInRequest = item => post("/api/StockIn", item);
const getStockInByIdRequest = id => get(`/api/StockIn/${ id }`);
const getConfirmStockInReqeust = id => get(`/api/StockIn/${ id }/confirm`);
const updateStockInRequest = item => update("/api/StockIn", item);
const deleteStockInRequest = id => del(`/api/StockIn/${ id }`);
const getStockInUploadTemplate = async fileName => {
  return await getFile(`/api/StockIn/uploadTemplate`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([ data ]), fileName);
    }
  });
};

const getStockInItemToExcel = async (id, fileName) => {
  return await getFile(`/api/StockIn/${ id }/exportToExcel`, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([ data ]), fileName);
    }
  });
};

function* getStockIns({ payload }) {
  try {
    const response = yield call(getStockInsRequest, payload);
    yield put(getStockInsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInsError(error));
  }
}

function* addNewStockIn({ payload }) {
  try {
    yield call(addNewStockInRequest, payload);
    const response = yield call(getStockInsRequest, {});
    yield put(getStockInsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInsError(error));
  }
}

function* updateStockIn({ payload }) {
  try {
    yield call(updateStockInRequest, payload);
    const response = yield call(getStockInsRequest, {});
    yield put(getStockInsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInsError(error));
  }
}

function* getStockInById({ payload }) {
  try {
    var response = yield call(getStockInByIdRequest, payload);
    yield put(getStockInByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInsError(error));
  }
}

function* getConfirmStockIn({ payload }) {
  try {
    const { stockInId, type } = payload;

    let response = yield call(getConfirmStockInReqeust, stockInId);
    if (type === "List") {
      response = yield call(getStockInsRequest, {});
      yield put(getStockInsSuccess(response));
    }
    yield put(getConfirmStockInSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInsError(error));
  }
}

function* deleteStockIn({ payload }) {
  try {
    yield call(deleteStockInRequest, payload);
    const response = yield call(getStockInsRequest, {});
    yield put(getStockInsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getStockInsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_STOCK_INS, getStockIns);
  yield takeEvery(ADD_NEW_STOCK_IN, addNewStockIn);
  yield takeEvery(GET_STOCK_IN_BY_ID, getStockInById);
  yield takeEvery(UPDATE_STOCK_IN, updateStockIn);
  yield takeEvery(DELETE_STOCK_IN, deleteStockIn);
  yield takeEvery(GET_CONFIRM_STOCK_IN, getConfirmStockIn);
}

export default itemSaga;

export { getStockInUploadTemplate, getStockInItemToExcel, getStockInByIdRequest };
